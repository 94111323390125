/* new styles - printReview */
/* new styles - printReview - start */
.printReview {
  .bp-l {
    .bp-l-b {
      .bp-l-b-pd {
        display: flex;
        flex-flow: row wrap;

        .bp-l-b-e {
          display: flex;
          flex-flow: column;

          width: 50%;

          label:nth-child(1) {
            font: normal normal normal 14px/19px Graphik-Regular;
            color: #666666;
          }

          label:nth-child(2) {
            font: normal normal bold 14px/19px Graphik-Medium;
            color: #1a1e26;
            word-wrap: break-word;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .bp-r {
    .page-heading {
      margin: 20px 35px;
    }

    .bp-r-h {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .o-s-a {
        display: flex;
        padding: 5px 10px;
        margin-left: auto;
        margin-top: -7px;
        border: 1px solid #ff6900;
        border-radius: 2px;
        width: 170px;

        justify-content: space-between;
        margin-bottom: auto;
        cursor: pointer;

        .active {
          color: #ff6900;
        }
      }

      .o-s-a-d {
        width: 1px;
        background: #aaa;
      }
    }

    .bp-r-h {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bp-r-h-e.dropdown {
        .bp-r-h-e-dd {
          position: relative;
          margin: 0 20px 0 0;

          button.btn {
            justify-content: flex-end;
            padding: 6px 10px !important;
            margin: 0;
            background: #ffffff !important;
            color: #666666;
            border: 1px solid #7b868e !important;
            border-radius: 4px !important;

            &:nth-child(1) {
              border-right: none !important;
              text-align: left;
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }

            &:nth-child(2) {
              border-left: none !important;
              text-align: right;
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          .dropdown-menu {
            left: -10px !important;

            width: auto;
            max-height: 200px !important;
            overflow-y: scroll;

            .dropdown-item {
              height: 40px;

              label {
                line-height: 30px;
              }

              &:active {
                background: #ff6900;
              }
            }
          }
        }
      }
    }
  }
}

section.print-review {
  display: flex;
  flex-flow: row;
  flex-grow: 1;

  .sliced-images {
    /* Hide dispense images div */
    display: none;
  }

  .sliced-images,
  .actual-images {
    flex-grow: 1;

    width: 50%;
    margin: 15px 10px 30px 15px;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;

    hgroup {
      display: flex;
      flex-flow: column;

      > div {
        display: flex;
        flex-flow: row;

        padding: 15px;

        label,
        div {
          margin: auto 15px auto 0px;

          align-self: center;
        }

        .separator {
          margin: 0 10px;
        }
      }
    }

    .select-group {
      display: flex;
      flex-flow: row-reverse;
      flex-grow: 1;

      .prev,
      .next {
        width: 30px;
        height: 30px;
        margin: auto 10px;
      }

      .prev {
        order: 2;

        mask: url("/img/navigate_before_black_24dp.svg");
        background: #fc6800;
        mask-size: cover;
        cursor: pointer;
      }

      .next {
        order: 1;

        mask: url("/img/navigate_next_black_24dp.svg");
        background: #fc6800;
        mask-size: cover;
        cursor: pointer;
      }
    }

    .image {
      flex-grow: 1;

      margin: 15px;
    }
  }
}

.print-review {
  .sliced-images {
    .viewBody {
      height: 100%;
      padding: 15px;

      img {
        display: flex;
        margin: auto;
        width: 100%;
      }
    }

    &.w-0 {
      width: 0px;
    }

    &.w-100 {
      width: 100%;
    }
  }

  .actual-images {
    .viewBody {
      height: 100%;
      padding: 15px;

      img {
        display: flex;
        margin: auto;
        width: 100%;
      }

      > div {
        height: 90%;
      }
    }

    &.w-0 {
      width: 0px;
    }

    &.w-100 {
      width: 100%;
    }
  }
}

/* new styles - printReview - end */
/* new styles - printReview */

/* old styles - printReview */
/* old styles - printReview - start */
.printReview {
  .bp-l {
    .bp-l-b {
      .bp-l-b-e {
        display: flex;
        margin: 10px 0px;
        font: normal normal normal 16px/22px Graphik-Regular;
        color: #666666;

        .bp-l-b-e-p {
          position: relative;

          .cancel-a-b-i {
            position: absolute;
            top: -10px;
            right: 2px;
            mask: url("/img/cancel_black_24dp.svg");
            background: #ff6900;
            mask-size: cover;
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }
}

/* old styles - printReview - end */
/* old styles - printReview */

.print_review_image {
  width: 100%;
  height: 100%;
}

.btn-print-review-primary {
  margin: 5px;
  background: #ff6900 !important;
  color: #fff !important;
  border: 1px solid #ff6900 !important;
  text-transform: capitalize !important;
}

.slider-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 44vw;
  padding-right: 20px;
  width: 100%;
}

.slider-count {
  display: flex;

  input {
    text-align: right;
  }
}

.LayerPreview-image-portrait img {
  transform: rotate(90deg);
}

.MuiSlider-root {
  margin: 0px 30px;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: bold;
}
.MuiInput-underline:after {
  display: none !important;
}

.btstrp.dropdown .btn-group button.btn.btn-dropdown:nth-child(1) {
  border-right: 1px solid !important;
  min-width: 220px;
}

.btn-dropdown.dropdown-toggle-split::after {
  position: absolute;
  right: 10px;
  top: 1em;
}

.dropdown-text {
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-label {
  font: normal normal normal 15px/19px Graphik-Regular;
  margin-right: 10px !important;
}

.page-header {
  margin-top: 30px;
  margin-left: 37px;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.radio-group {
  .form-check-input {
    transform: scale(1.5);
  }
}

.feedback-contents {
  font: normal normal 600 16px/21px Graphik-Regular;
  color: #1a1e26;
  display: flex;
  flex-direction: column;

  .feedback-contents-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .feedback-content-info {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .feedback-content-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

    > * {
      flex: 1;
    }

    .radio-group {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.range-rating {
  datalist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    writing-mode: vertical-lr;
    width: 100%;
  }

  option {
    padding: 0;
  }

  input[type="range"] {
    width: 100%;
    margin: 0;
  }
}

.feedback-btn {
  width: 200px;
  align-self: center;
  margin-top: 50px;
}

.multi-select {
  width: 300px;
  max-width: 300px;
  border: 1px solid #aeaeae !important;
  border-radius: 5px;
  padding: 10px !important;

  label + .MuiInput-formControl {
    margin-top: auto !important;
    padding: 10px;
  }

  .MuiSelect-selectMenu {
    background: none !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none !important;
  }
}

.feedback-form-label {
  font: normal normal normal 14px/20px Graphik-Regular;
  margin-bottom: 10px;
  color: #1a1e26;
}

.feedback-slider {
  width: 94%;
  margin: 30px 0px;

  .MuiSlider-marked {
    padding: 0px;
    color: #9fa0a3;
    margin-left: 0px;
  }

  .MuiSlider-root .MuiSlider-mark {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: -5px;
    background-color: #ff6900;
  }

  .MuiSlider-rail {
    height: 7px !important;
  }

  .MuiSlider-root .MuiSlider-track {
    height: 7px;
    background-color: #fc6800;
  }
  .MuiSlider-root .MuiSlider-thumb {
    height: 20px !important;
    width: 20px;
    border-radius: 50% !important;
    background: #ff6900;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 2px white;
    margin-left: 0px;
    top: -4px;
    margin-top: -4px;
  }
  .MuiSlider-markLabel {
    margin-left: 11px;
  }
}
.display-rating-legends {
  padding: 0px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  li {
    list-style: none;
    margin-right: 15px;
    label {
      font: normal normal normal 14px/18px Graphik-Regular !important;
      color: #333333;
    }
  }
}
.radio-group {
  label {
    height: 35px;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 16px !important;
    font-weight: bold;
    color: #000 !important;
    margin-top: -5px;
  }
  .MuiIconButton-root {
    &:hover {
      background-color: #fff !important;
    }
    .MuiTypography-body1 {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.printer-name {
  font-size: 18px !important;
  font-family: "Graphik-Bold";
  color: #ff6900;
}
.MuiPopover-paper {
  max-height: 450px !important;
  ul > li {
    text-wrap: balance;
  }
}
.slider-count {
  .MuiInput-input {
    background: #fff;
    padding: 5px;
    text-align: right;
    width: 80px;
    margin-right: 49px;
    border: 1px solid #aeaeae;
    height: 23px;
    font-size: 20px;
    font-weight: 500;
  }
  
}
.no-image-message{
  font: normal normal bold 18px/19px Graphik-Medium;
}
.no-image-info {
font: normal normal normal 20px/20px Graphik-Regular !important;
text-align: center;
margin-top: 15%;
}
.fullscreen-modal{
min-width: 100% !important;
margin: 0px !important;
height: 100% !important;
.modal-content{
  height: 100% !important;
  .modal-body{
    display: flex;
    justify-content: center;
  }
}
}
.config-details {
  max-height: 65vh;
  .label-text {
    font: normal normal normal 14px/18px Graphik-Regular;
    color: #666666;
    padding-top: 24px;
    padding-bottom: 5px;
  }
  .part-not-available .label-text {
    margin-top: 20px;
  }
}
.nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.nav-tabs .nav-link {
  border: 0px solid transparent;
  min-width: 50px;
  margin: 5px 25px;
  padding: 5px !important;
}

.nav-item {
  color: #666666;
  font-family: 'Graphik-Semibold';
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #ff6900;
}