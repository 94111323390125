.loadingComponentBackground {
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(220, 220, 220, 0.5);
  z-index: 999;
}

.loadingComponentContainer {
  position: relative;
  left: 40%;
  top: 40%;
  width: 20%;
  height: 10%;
}
