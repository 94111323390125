@font-face {
  font-family: "Graphik-Medium";
  src: url("../Beta/Fonts/Graphik/GraphikMedium.otf") format("opentype"),
    url("../Beta/Fonts/Graphik/Graphik-Medium.woff2") format("woff2"),
    url("../Beta/Fonts/Graphik/Graphik-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Bold";
  src: url("../Beta/Fonts/Graphik/GraphikBold.otf") format("opentype"),
    url("../Beta/Fonts/Graphik/Graphik-Bold.woff2") format("woff2"),
    url("../Beta/Fonts/Graphik/Graphik-Bold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("../Beta/Fonts/Graphik/GraphikSemibold.otf") format("opentype"),
    url("../Beta/Fonts/Graphik/Graphik-Semibold.woff2") format("woff2"),
    url("../Beta/Fonts/Graphik/Graphik-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("../Beta/Fonts/Graphik/GraphikRegular.otf") format("opentype"),
    url("../Beta/Fonts/Graphik/Graphik-Regular.woff2") format("woff2"),
    url("../Beta/Fonts/Graphik/Graphik-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
	font-family: 'OpenSans-Bold';
	src: url('../Beta/Fonts/OpenSans/OpenSans-Bold.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans-Regular';
	src: url('../Beta/Fonts/OpenSans/OpenSans-Regular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans-Bold';
	src: url('../Beta/Fonts/OpenSans/OpenSans-Bold.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans-Regular';
	src: url('../Beta/Fonts/OpenSans/OpenSans-Regular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans-SemiBold';
	src: url('../Beta/Fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
	font-display: swap;
}
body {
  margin: 0;
  overflow-x: hidden;
}

canvas {
  height: 100%;
  width: 100%;
  display: block;
}

.btn-group-vertical {
  width: 100%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff6900;
  background-color: #ff6900;
}

.custom-control-label::before {
  width: 23px !important;
  height: 23px !important;
}

.custom-control-label::after {
  width: 23px !important;
  height: 23px !important;
}

.p-14 {
  font-size: 14px !important;
}

.p-16 {
  font-size: 16px !important;
}

.c-666 {
  color: #666666;
}

.c-292929 {
  color: #292929;
}

.custom-sign-out {
  position: absolute;
  right: 10px;
  top: 21px;
}

.f-w {
  width: 100%;
}

.h-w {
  width: 50%;
}

.MuiDialog-container {
  .MuiDialogTitle-root {
    font-size: 20px;
    font-weight: bold;

    h2 {
      font-size: 20px !important;
      font-weight: bold !important;
    }
  }

  .MuiDialogContent-root {
    font-size: 20px;
  }

  .MuiDialogActions-root {
    .btn {
      font-size: 16px !important;
    }
  }

  .each-dialog-content {
    .each-d-section {
      margin: 15px 0;
    }
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 10px;
  border: 6px solid transparent;
  background-clip: content-box;
  height: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
