.modePickerContainer {
	position: absolute;
	top: 0;
	left: 15px;
}

.modePickerContainer,
.modePickerSubContainer {
	display: flex;
	flex-direction: column;
}

.modelPickerButton {
	width: 64px;
	height: 64px;
	margin-top: 20px;
}

.invisible {
  display: none;
}

button.viewControlButton {
  border: 1px solid grey;
  border-radius: 16px;
  height: 20px;
  background: white;
}

.rotated {
  transform: rotate(90deg);
}

.smaller {
  margin-top: -5px;
  margin-bottom: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 35px;
}

button.viewControlButton:hover {
  background: lightgrey;
}

.viewControlButton {
  width: 140px;
  height: 20px;
  overflow: hidden;
  margin-top: 5px;
}

.viewControlButton .MuiFormControlLabel-root {
  padding: 0px;
  margin-top: -10px;
}

.viewControlButton .MuiSvgIcon-root {
  fill: black;
}

.modePickerContainerItem {
	display: inline-flex;
	flex-direction: row;
  width: 100%;
}

.modePickerContainerItem .icon {
	width: 30px;
	height: 30px;
	margin: 10px 0;
	cursor: pointer;
	background: #000000;
}

.modePickerContainerItem .icon+.tooltiptext {
	z-index: 1;
	padding: 10px;
	display: none;
	background-color: transparent;
	color: #FF6900;
	text-align: left;
	line-height: 30px;
}

.modePickerContainerItem .icon:hover+.tooltiptext {
	display: block;
}

.modePickerContainerItem .icon.active {
	background: #FF6900;
}

.more {
	mask: url("/img/more.svg");
	mask-size: cover;
}

.left-view-icon {
  mask: url("/img/left-view.svg");
  mask-size: cover;
}

.front-view-icon {
  mask: url("/img/top-view.svg");
  mask-size: cover;
}

.top-view-icon {
  mask: url("/img/front-view.svg");
  mask-size: cover;
}

.grid-icon {
  mask: url("/img/grid-view.svg");
  mask-size: cover;
}

.translate {
	mask: url("/img/new-translate.svg");
	mask-size: cover;
}

.rotate {
	mask: url("/img/new-rotate.svg");
	mask-size: cover;
}

.move-to-centre {
	mask: url("/img/move-to-centre.svg");
	mask-size: cover;
}

.face-up-orient {
	mask: url("/img/face-up-orient.svg");
	mask-size: cover;
}

.snap {
	mask: url("/img/snap.svg");
	mask-size: cover;
}

.dual-z {
	mask: url("/img/dual-z.svg");
	mask-size: cover;
}

.scale {
	mask: url("/img/scale.svg");
	mask-size: cover;
}