/******Mantle Documents*******/
table {
    font: normal normal normal 16px/18px Graphik-Regular;
    margin-top: 20px;
    margin: 80px 3rem 3rem;
    width: 95% !important;
    overflow-x: hidden;
  }
  .table-header {
    background: #374352;
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
  }
  .link {
    color: #FF6900;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #1F2A37;
    }
  }
/*******Data Tables****/
html.dark table.dataTable tr.dt-hasChild td.dt-control:before {
    color: rgba(255, 255, 255, 0.5)
  }
  
  table.dataTable thead>tr>th.sorting,table.dataTable thead>tr>th.sorting_asc,table.dataTable thead>tr>th.sorting_desc,table.dataTable thead>tr>th.sorting_asc_disabled,table.dataTable thead>tr>th.sorting_desc_disabled,table.dataTable thead>tr>td.sorting,table.dataTable thead>tr>td.sorting_asc,table.dataTable thead>tr>td.sorting_desc,table.dataTable thead>tr>td.sorting_asc_disabled,table.dataTable thead>tr>td.sorting_desc_disabled {
    cursor: pointer;
    position: relative;
    padding-right: 26px
  }
  
  table.dataTable thead>tr>th.sorting:before,table.dataTable thead>tr>th.sorting:after,table.dataTable thead>tr>th.sorting_asc:before,table.dataTable thead>tr>th.sorting_asc:after,table.dataTable thead>tr>th.sorting_desc:before,table.dataTable thead>tr>th.sorting_desc:after,table.dataTable thead>tr>th.sorting_asc_disabled:before,table.dataTable thead>tr>th.sorting_asc_disabled:after,table.dataTable thead>tr>th.sorting_desc_disabled:before,table.dataTable thead>tr>th.sorting_desc_disabled:after,table.dataTable thead>tr>td.sorting:before,table.dataTable thead>tr>td.sorting:after,table.dataTable thead>tr>td.sorting_asc:before,table.dataTable thead>tr>td.sorting_asc:after,table.dataTable thead>tr>td.sorting_desc:before,table.dataTable thead>tr>td.sorting_desc:after,table.dataTable thead>tr>td.sorting_asc_disabled:before,table.dataTable thead>tr>td.sorting_asc_disabled:after,table.dataTable thead>tr>td.sorting_desc_disabled:before,table.dataTable thead>tr>td.sorting_desc_disabled:after {
    position: absolute;
    display: block;
    opacity: .125;
    right: 10px;
    line-height: 9px;
    font-size: .8em
  }
  
  table.dataTable thead>tr>th.sorting:before,table.dataTable thead>tr>th.sorting_asc:before,table.dataTable thead>tr>th.sorting_desc:before,table.dataTable thead>tr>th.sorting_asc_disabled:before,table.dataTable thead>tr>th.sorting_desc_disabled:before,table.dataTable thead>tr>td.sorting:before,table.dataTable thead>tr>td.sorting_asc:before,table.dataTable thead>tr>td.sorting_desc:before,table.dataTable thead>tr>td.sorting_asc_disabled:before,table.dataTable thead>tr>td.sorting_desc_disabled:before {
    bottom: 50%;
    content: "▲";
    content: "▲"/"";
    padding-bottom: 2px;
  }
  
  table.dataTable thead>tr>th.sorting:after,table.dataTable thead>tr>th.sorting_asc:after,table.dataTable thead>tr>th.sorting_desc:after,table.dataTable thead>tr>th.sorting_asc_disabled:after,table.dataTable thead>tr>th.sorting_desc_disabled:after,table.dataTable thead>tr>td.sorting:after,table.dataTable thead>tr>td.sorting_asc:after,table.dataTable thead>tr>td.sorting_desc:after,table.dataTable thead>tr>td.sorting_asc_disabled:after,table.dataTable thead>tr>td.sorting_desc_disabled:after {
    top: 50%;
    content: "▼";
    content: "▼"/""
  }
  
  table.dataTable thead>tr>th.sorting_asc:before,table.dataTable thead>tr>th.sorting_desc:after,table.dataTable thead>tr>td.sorting_asc:before,table.dataTable thead>tr>td.sorting_desc:after {
    opacity: .6
  }
  
  .dataTables_paginate {
    display: none;
  }
  .dataTables_filter, .dataGrid-wrapper-filter {
    background: #fff;
    border-bottom: 1px solid #cecece;
    padding: 0 3rem;
    height: 70px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 160px;
    z-index: 9;
    width: 100%;
      label {
        width: 70%;
        input {
            font: normal normal 600 16px/21px Graphik-Regular;
            width: 100%;
            border-radius: 25px;
            height: 53px;
            color: #1A1E26;
            padding: 0 30px 0 50px;
            background: #F4F4F4 url(/search-icon.svg) no-repeat 20px 19px;
            position: relative;
            border: 2px solid #fff;
            width: 100%;
        }
      }

  }
  .dataTables_info {
    padding: 0 3rem;
  }
  .dataTables_length {
    position: absolute;
    right: 18%;
    top: 180px;
    z-index: 10;
    font: normal normal 600 16px/21px Graphik-Regular;
    select {
        padding: 6px;
    }
  }
  .upload-btn {
    width: fit-content;
    position: absolute;
    right: 3%;
    z-index: 99;
  }
  .DTFC_LeftFootWrapper {
    display:none;
}
 
/*********Grid Table************/
.MuiDataGrid-columnsContainer {
  background: #374352;
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
}
.MuiDataGrid-root {
  font: normal normal normal 16px/18px Graphik-Regular;
    margin-top: 20px;
    margin: 80px 3rem 3rem;
    width: 95% !important;
    overflow-x: hidden;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
}

.MuiDataGrid-root, .table-date-format {
  font: normal normal normal 16px/18px Graphik-Regular !important;
}
.link-format, .MuiTypography-subtitle1 {
  color: #ff6900 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  &:hover {
    text-decoration: none !important;
  }
}

.dataGrid-wrapper-filter{
  display: flex;
  //justify-content: space-between;
}
.table-wrapper-docs{
  margin-top: 85px;
  width: 100%;
  padding: 0 3rem;

  .MuiDataGrid-root {
    width: 100% !important;
  }
  
  .MuiIconButton-root {
    color: #fff !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, 
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .MuiIconButton-colorInherit {
    color: rgba(0, 0, 0, 0.54) !important;
  }
}
.help-search-box {
    font: normal normal 600 16px/21px Graphik-Regular;
    width: 100%;
    border-radius: 25px;
    height: 53px;
    color: #1A1E26;
    padding: 0 30px 0 50px;
    background: #F4F4F4 url(/search-icon.svg) no-repeat 20px 19px;
    position: relative;
    border: 2px solid #fff;
}
.form-control:focus, .form-control.active {
  background-color: transparent !important;
  border-color: none !important;
  box-shadow: none !important;
}
.MuiSvgIcon-root {
  font-size: 12px !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon {
  opacity: 0.6 !important;
}