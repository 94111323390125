.flex-d {
  display: flex;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.dashboard {
  // width: calc(100vw - 100px);
  width: 100%;
  // height: calc(100vh - 90px);
  min-height: 100%;
  overflow: auto;
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      #ffffff 0%,
      #f4f4f5 140%,
      #c8c8cf 280%
    )
    0% 0% no-repeat padding-box;
}

.highlighter {
  height: 30px;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
}

.text-h-l {
  font: normal normal bold 22px/30px Graphik-Medium;
  letter-spacing: 0px;
  color: #1a1e26;
}

.actionSpace {
  width: 300px;
}

.horizontal-active::after {
  content: "";
  position: absolute;
  height: 7px;
  width: 40px;
  background-color: #ff6900;
  bottom: 0;
  border-radius: 50% 50% 0 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.horizontal-active {
  border-bottom: 2px solid;
  border-image-source: linear-gradient(to left, #f6f6f7, #1a1e26, #f6f6f7);
  border-image-slice: 1;
}

.breadcrumb {
  margin: 0;
  padding: 20px 40px;
  background: transparent;

  .breadcrumb-item {
    a {
      color: #0e0e0e;
      text-decoration: none !important;
    }

    & + .breadcrumb-item::before {
      content: ">";
    }

    &.active {
      color: #ff6900;
    }
  }
}

/* Dashboard Page */

section.page {
  .dashboard-page {
    .display-card {
      // height: 300px;
      height: 240px;
      // width: 360px !important;
      padding: 20px 0px;
      .img-col {
        display: flex;
        background: #d8dcd6;
        justify-content: center;
        height: 115px;
        align-items: center;
        &.folder-img {
          background: #fff;
        }
        .thumbnail-img {
          width: auto;
          max-height: 50px;
          max-width: 255px;
          &.plate-thumbnail {
            max-height: 115px;
          }
        }
        &.plate-thumb {
          background: #fff url(/tile-bg.png)no-repeat 10px;
          border-radius: 5px;
        }
      }

      .display-card-row {
        min-height: auto !important;
        font-family: OpenSans-Regular !important;
        .display-card-header {
          border-bottom: 1px solid #f4f4f5;
          border-top: 1px solid #f4f4f5;
          padding: 0px 5px !important;
          label {
            color: #ff6900 !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px 0px;
            width: 280px;
            @media only screen and (min-width: 1536px) and (max-width: 1919px) {
              width: 210px;
            }
          }
        }
        .details,
        .display-card-sub-header label {
          display: flex;
          font-size: 14px !important;
          padding-bottom: 5px;
          @media only screen and (min-width: 1536px) and (max-width: 1919px) {
            font-size: 12px !important;
          }
          label {
            min-width: 130px;
            margin-bottom: 5px !important;
            color: #666666;
            font-family: OpenSans-Regular !important;
            @media only screen and (min-width: 1536px) and (max-width: 1919px) {
              min-width: 115px;
            }
          }
          span {
            font-weight: bold;
            white-space: nowrap;
            width: 56%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .display-card-more-action {
          right: 5px;
          position: relative;
        }
      }
      .part-image {
        height: 110px;
        float: left;
        padding: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1536px) and (max-width: 1919px) {
  section.page .plates-page {
		zoom: 80%;
	}
  section.page .dashboard-page .display-card {
    max-height: 305px;
    padding: 20px 0px;
    .img-col {
      max-height: 140px;
      .thumbnail-img {
        max-height: 50px;
      }
    }
  }
}
.MuiDataGrid-root {
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.icon.build {
  content: url("/build-thumbnail.svg");
}
.icon {
  width: 30px;
  height: 30px;
  padding: 5px;
  margin: 5px;
}
.icon.part {
  content: url("/part-thumbnail.svg");
}

.icon.plate {
  content: url("/plate-thumbnail.svg");
}
.list-view-build-img {
  width: 25px;
}
.progress-flex-section {
  display: flex;

  .progress {
    width: 150px;
    margin: 1rem;
  }
}
.display-list-more-action {
  #dropdown-basic {
    padding: 0;

    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;

    &:after {
      content: unset;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.list-view-grid {
  .MuiDataGrid-cell:not(:nth-child(3)) {
    overflow: visible !important;
  }
  .dropdown-menu.show {
    line-height: 30px !important;
  }
  .MuiDataGrid-footerContainer {
    display: none !important;
  }
  .MuiDataGrid-sortIcon  {
    color: #fff !important;
  }
  // disable cell selection style
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-cell{
    cursor: pointer !important;
  }
  .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    display: none;
  }
  .MuiDataGrid-cellCheckbox { 
    span {
      display: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  .enable-checkbox-row {
    .MuiDataGrid-cellCheckbox {
      pointer-events: unset !important;
      span {
        display: flex !important;
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  .MuiDataGrid-row {
    .MuiDataGrid-cellCheckbox {
      pointer-events: none;
    }
  }
}