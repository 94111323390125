.buildSliceReview {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  > div {
    height: 100%;
  }
  .warning-icon-wrapper {
    box-shadow: none !important;
    position: absolute;
    padding: 0 !important;
    cursor: pointer;
  }
}
div.build-review-reduced-margin {
  // margin-bottom: -20px;
}
.buildSliceSceneControls {
  position: absolute;
  top: 10px;
  right: 10px;
  .btn-build-review-primary {
    height: 25px;
    width: 100px;
    margin-bottom: 3px;
  }
  .build-review-scene-controls {
    text-transform: uppercase;
    font-weight: bold;
    color: #1e2a32;
  }
  .icon {
    width: 45px;
    height: 45px;
    cursor: pointer;
    -webkit-box-shadow: 0px 5px 10px 3px rgba(200,200,207,0.75);
    -moz-box-shadow: 0px 5px 10px 3px rgba(200,200,207,0.75);
    box-shadow: 0px 5px 10px 3px rgba(200,200,207,0.75);
    margin: 10px 0 10px 30px;
    border-radius: 5px;
    &.top-view {
      background: url("/img/top-view-icon.svg");
      &:hover, &.active-btn {
        background: url("/img/top-view-icon-active.svg");
      }
    }
    &.left-view {
      background: url("/img/left-view-icon.svg");
      &:hover, &.active-btn {
        background: url("/img/left-view-icon-active.svg");
      }
    }
    &.front-view {
      background: url("/img/front-view-icon.svg");
      &:hover, &.active-btn {
        background: url("/img/front-view-icon-active.svg");
      }
    }
    &.grid-view {
      background: url("/img/grid-view-icon.svg");
      &:hover, &.active-btn {
        background: url("/img/grid-view-icon-active.svg");
      }
      .MuiFormControlLabel-root {
        .MuiButtonBase-root {
          padding: 15px;
          visibility: hidden;
        }
      }      
    }
    &.dual-z-show {
      background: url("/img/dual-z-show-icon.svg");
      &:hover, &.active-btn {
        background: url("/img/dual-z-show-icon-active.svg");
      }
      .MuiFormControlLabel-root {
        .MuiButtonBase-root {
          padding: 15px;
          visibility: hidden;
        }
      }      
    }
    
  }
}
.buildViewAction {
  left: 0;
  width: 100%;
  .buildViewActionSlied {
    // width: 58%;
    // min-width: 100px;
    height: 20px;
    flex-grow: 1;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #72727229;
    border-radius: 20px;
    // padding: 0px 20px 0px 20px;
    margin-bottom: 5px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 30px 20px 0px;
    .MuiSlider-root {
      margin: 0px 10px !important;
    }
    .MuiSlider-marked {
      margin-bottom: 0 !important;
      .MuiSlider-markLabel {
        line-height: 3 !important;
        font-weight: bold !important;
      }
      // .MuiSlider-thumb::first-child {
      //         .MuiSlider-valueLabel {
      //             transform: scale(-1) translateY(-20px) !important;
      //             span {
      //                 span {
      //                     transform: rotate(-135deg) !important;
      //                 }
      //             }
      //         }
      // }
    }
  }
  .buildViewActionCheck {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    > div {
      margin-right: 25px;
    }
    .form-check {
      padding-left: 10px;
    }
    .cut-options {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .cut-lable {
        font: normal normal bold 17px/27px Graphik-Regular;
      }
      .cut-options-val {
        margin-left: 10px;

        .dropdown {
          .btn-primary:not(:disabled):not(.disabled).active:focus,
          .btn-primary:not(:disabled):not(.disabled):active:focus,
          .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: none !important ;
          }
          .btn-primary:not(:disabled):not(.disabled).active,
          .btn-primary:not(:disabled):not(.disabled):active,
          .show > .btn-primary.dropdown-toggle {
            color: #666 !important;
            background-color: #fff !important;
            border: 1px solid #9b9b9b59 !important;
            border-radius: 6px !important;
          }
          .btn-primary {
            color: #666 !important;
            background-color: #fff !important;
            border: 1px solid #9b9b9b59 !important;
            border-radius: 6px !important;
            font-size: 14px!important;
            font-family: 'Graphik-Regular'!important;
            min-width: 183px;
            text-align: left;
          }
         
          .dropdown-toggle::after {
            border-top: 0.3em solid #ff6900 !important;
            float: right;
            margin-top: 7px;
          }
          .dropdown-item {
            font-family: 'Graphik-Regular'!important;
            font-size: 14px!important;
            height: 30px;
            margin-bottom: 10px;
            &:active{
              background-color: #9fbcf1!important;
            }
            .MuiIconButton-root {
              padding: 0 10px 0 0!important;
            }
          }
        }
      }
    }
  }
  .MuiSlider-root {
    height: 5px;
    color: #C8C8CF;
    .MuiSlider-rail {
      height: 5px;
    }
    .MuiSlider-track {
      height: 5px;
      background-color: #fc6800;
    }
    .MuiSlider-mark {
      width: 5px;
      height: 5px;
    }
    .MuiSlider-thumb {
      height: 30px;
      margin-top: -13px;
      border-radius: 5px;
      background: #fc6800;
      > span {
        > span {
          background: #fc6800;
        }
      }
    }
  }
}
.btn-build-review-primary {
  margin: 5px;
  background: #ff6900 !important;
  color: white !important;
  border: 1px solid #ff6900 !important;
  border-radius: 15px !important;
  &:focus {
    outline: none;
    border: none;
  }
  text-transform: capitalize !important;
}
.btn-build-review-secondary {
  background: #ffffff !important;
  color: #ff6900 !important;
  border: 1px solid #ff6900 !important;
  border-radius: 15px !important;
  &:focus {
    outline: none;
    border: none;
  }
  text-transform: capitalize !important;
}
.btn-build-review-primary {
  margin-right: 38px !important;
  height: 40px;
  border-radius: 10px !important;
}
.expand-collapse-btn {
  svg {
    width: 30px;
    height: 30px;
  }
  cursor: pointer;
}
.cutSim-mesh-btn {
  padding: 5px 15px !important;
  height: 38px;
  width: 55px !important;
  min-width: 0 !important;
  border: 1px solid #9b9b9b59 !important;
  border-radius: 6px !important;
  font: normal normal 500 14px/22px "Graphik-Regular";
  color: #393939 !important;
  text-transform: capitalize !important;
  background: #fff!important;
}
