/* new styles - buildPreparation */
/* new styles - buildPreparation - start */
.buildPreperation {
  .bp-l {
    .bp-l-b {
      .bp-l-b-e {
        display: flex;
        margin: 10px 0px;

        label {
          margin: 0;
        }
      }

      .bp-l-b-e.part,
      .bp-l-b-e.printer {
        display: flex;
        flex-flow: column;
        margin: 10px 0px;

        label {
          margin: 0;
        }

        section {
          display: flex;
          flex-flow: row wrap;

          .bp-l-b-e-p,
          .bp-l-b-e-pr {
            position: relative;
            margin: 10px 10px 10px 0;

            .cancel-a-b-i {
              position: absolute;
              top: -10px;
              right: 2px;

              width: 25px;
              height: 25px;

              mask: url("/img/cancel_black_24dp.svg");
              background: #ff6900;
              mask-size: cover;
              cursor: pointer;
            }

            .parts-b-i {
              width: 40px;
              height: 40px;
              margin: auto 10px;

              mask: url("/img/settings_black_24dp.svg");
              background: black;
              mask-size: cover;
            }

            .add-b-i {
              width: 40px;
              height: 40px;
              margin: auto 10px;

              mask: url("/img/add_black_24dp.svg");
              background: black;
              mask-size: cover;
            }
          }

          .bp-l-b-e-pr {
            > div {
              &:nth-child(1) {
                font: normal normal bold 14px/19px Graphik-Medium;
                letter-spacing: 0px;
                color: #393939;
                margin-bottom: 5px;
              }

              &:nth-child(2) {
                font: normal normal bold 14px/19px Graphik-Medium;
                letter-spacing: 0px;
                color: #ff6900;
                cursor: pointer;
              }
            }
          }
        }
      }

      .bp-l-b-e.dropdown {
        display: flex;
        flex-flow: column;

        .bp-l-b-e-h {
          .edit-a-b-i {
            position: relative;
            right: 10px;
            float: right;

            width: 16px;
            height: 16px;

            mask: url("/img/edit.svg");
            background: #ff6900;
            mask-size: cover;
            cursor: pointer;
          }
        }
        .bp-l-b-e-dd {
          position: relative;
          margin: 10px 10px 10px 0;

          button.btn {
            color: #212529;
            background-color: transparent;

            &:nth-child(1) {
              border-right: none;
              text-align: left;
            }

            &:nth-child(2) {
              border-left: none;
              text-align: right;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          .dropdown-menu {
            left: -5px !important;

            width: 100%;

            .dropdown-item {
              height: 40px;

              label {
                line-height: 30px;
              }
              &:active {
                background: #ff6900;
              }
            }
          }
        }
      }
    }
  }
}
/* new styles - buildPreparation - end */
/* new styles - buildPreparation */

/* old styles - buildPreparation */
/* old styles - buildPreparation - start */
.buildPreperation {
  .bp-l {
    .b-p-acc {
      .MuiAccordionSummary-root {
        padding: 0 15px 0px 0px;
      }
      .MuiAccordionDetails-root {
        padding: 8px 0;
      }
    }
  }
  .show-parts-adding {
    display: flex;
    .buildViewScreen {
      width: 50%;
    }
  }
}
/* old styles - buildPreparation - end */
/* old styles - buildPreparation */

.display-header-title {
  font: normal normal normal 16px/19px Graphik-Regular;
  color: #7E7E7E;
  .display-header-value {
    font: normal normal normal 18px/19px Graphik-Medium;
    padding-left: 12px;
    color: #1A1E26;
  }
}
