/* scrollbar styles - common across the application */
/* scrollbar styles - start */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(26, 30, 38, 0.22) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff6900 !important;
}

/* scrollbar styles - end */
/* scrollbar styles - common across the application */

/* input styles - start */
/* input styles - common across the application */
input.btstrp.form-control {
  width: calc(100% - 10px);
  height: 38px;
  margin: 10px 10px 10px 0;

  color: #212529;
  background-color: transparent;
  border: 1px solid #6c757d;

  &:hover {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

/* input styles - common across the application */

/* input styles - end */

/* button styles - start */
.btn {
  border-radius: 10px !important;
  padding: 7px 20px !important;
  font-family: Graphik-Medium !important;
  font-size: 16px !important;
  border: 0 none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.btn-primary {
  background: #ff6900 !important;

  span {
    margin: 0 5px;
    display: inline-flex;
    vertical-align: middle;

    &.right-arrow {
      width: 10px;
      height: 20px;
      background: url(/right-arrow-white.svg) no-repeat;
    }
  }
}

.btn-secondary {
  background: #1f2a37 !important;

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-tertiary {
  background: #bfbfbf !important;
}

.btn-primary,
.btn-secondary,
.btn-tertiary {
  &:focus {
    box-shadow: none !important;
  }
}

.btn-space {
  margin: 0 26px;
}

.btn-inactive {
  pointer-events: none;
  background-color: #bfbfbf !important;
}

.btn-primary.disabled {
  background-color: #bfbfbf !important;
}

/* button styles - common across the application */
button.btn.outline-secondarytextactive-btn.btn-outline-secondary,
button.btn.outline-secondarytextgrey-btn.btn-outline-secondary {
  height: 44px;
  margin: 0;

  // border-radius: 44px !important;
}

.btn-outline-secondary {
  color: #353535;

  &.outline-secondarytext-btn {
    margin-right: 10px;
    color: #ff6900 !important;
    border-color: #ffffff !important;
    background: #ffffff !important;
    // border-radius: 15px !important;
    padding: 9px 15px !important;
    font: normal normal normal 16px/20px Graphik-Medium !important;

    &:hover {
      color: #fff !important;
      background-color: #ff6900 !important;
      border-color: #ff6900 !important;
    }

    &:focus {
      box-shadow: unset !important;
    }
  }

  &.outline-secondarytextactive-btn {
    margin-right: 10px;
    color: #fff !important;
    border-color: #ff6900 !important;
    background: #ff6900 !important;
    border-radius: 15px !important;
    font: normal normal bold 15px/20px Graphik-Medium !important;
    padding: 9px 15px !important;

    &:focus {
      box-shadow: unset !important;
    }
  }

  &.outline-secondarytextgrey-btn {
    margin-right: 10px;
    color: #333333 !important;
    border-color: rgba(26, 30, 38, 0.22) !important;
    background: rgba(26, 30, 38, 0.22) !important;
    border-radius: 15px !important;
    font: normal normal bold 15px/20px Graphik-Medium !important;
    padding: 9px 15px !important;

    &:focus {
      box-shadow: unset !important;
    }
  }

  &.outline-secondaryicon-btn {
    padding: 10px 3px;
    margin-right: 10px;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;

    &:hover {
      background-color: #f2f2f2 !important;
      border-color: #f2f2f2 !important;

      .btnIcon {
        background: #ff6900 !important;
      }
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  &.outline-secondaryiconactive-btn {
    padding: 10px 3px;
    margin-right: 10px;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;

    .btnIcon {
      background: #ff6900 !important;
    }

    &:hover {
      background-color: #f2f2f2 !important;
      border-color: #f2f2f2 !important;

      .btnIcon {
        background: #ff6900 !important;
      }
    }

    &:focus {
      box-shadow: unset !important;
    }
  }
}

/* button styles - common across the application */
/* button styles - end */

/* dropdown styles - common across the application */
/* dropdown styles - start */
.btstrp.dropdown {
  display: flex;
  flex-flow: column;

  .dropdown-label {
    font-size: 16px;
    color: #7e7e7e;
    font-family: "Graphik-Regular";
    margin: 22px 0 0;
  }

  .btn-group {
    position: relative;
    margin: 10px 10px 10px 0;

    button.btn {
      justify-content: flex-end;
      padding: 7px 10px !important;
      margin: 0;
      background: #f4f4f5 !important;
      color: #000000;
      border: 1px solid #f4f4f5 !important;
      border-radius: 6px !important;

      &:nth-child(1) {
        border-right: none !important;
        text-align: left;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &:nth-child(2) {
        border-left: none !important;
        text-align: right;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      &:focus {
        box-shadow: none !important;
        outline: none;
      }
    }

    .dropdown-menu {
      width: 100%;
      max-height: 50vh;

      overflow: auto;

      .dropdown-item {
        height: 40px;

        label {
          line-height: 30px;
        }

        &:active {
          background: #ff6900;
        }
      }
    }
  }
}

/* dropdown styles - end */
/* dropdown styles - common across the application */

/* MuiSlider styles - common across the application */
/* MuiSlider styles - start */
.MuiSlider-root {
  height: 5px;

  color: #eeeeee;

  .MuiSlider-rail {
    height: 5px;
  }

  .MuiSlider-track {
    height: 5px;

    background-color: #fc6800;
  }

  .MuiSlider-mark {
    width: 5px;
    height: 5px;
  }

  .MuiSlider-thumb {
    height: 30px;
    margin-top: -13px;
    background: #fc6800;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 6px rgb(255 255 255);

    > span {
      > span {
        background: #fc6800;
      }
    }
  }
}

/* MuiSlider styles - end */
/* MuiSlider styles - common across the application */

/* common styles - when left menu is present */
/* common styles - when left menu is present - start */
/* pages involved: MVPBuildPreparation, MVPPrintReview, MVPConfigurationDashboard, MVPBPConfiguration */
.buildPreperation,
.printReview,
.MVPConfigurationDashboard,
.MVPBPConfiguration {
  display: flex;
  flex-flow: row;

  height: calc(100vh - 90px);

  .bp-l {
    display: flex;
    flex-flow: column;

    width: 550px;
    height: 100%;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e1e1e1;
    opacity: 1;

    .bp-l-h {
      display: flex;
      flex-flow: row;

      width: 100%;
      height: 70px;

      border-bottom: 1px solid #ccc;

      div.back-icon,
      label {
        height: 30px;
        margin: auto 5px auto 10px;
      }

      .back-icon {
        margin: auto 10px;
        background: url(/img/back-arrow.svg) no-repeat;
        height: 22px;
        width: 36px;
        cursor: pointer;
      }

      label {
        flex-grow: 1;

        text-align: left;
        font-size: 18px;
        font-family: "Graphik-Medium";
        letter-spacing: 0px;
        color: #1a1e26;
      }
    }

    .bp-l-b,
    .bp-l-b-only {
      height: calc(100% - 142px);
      margin: 0 0 0 20px;

      font-family: "Graphik-Medium";
      overflow: auto;

      .bp-l-b-h {
        display: flex;
        flex-flow: row;

        margin: 20px 0;

        label {
          margin: 0;
        }

        .bp-l-b-br {
          flex-grow: 1;

          height: 1px;
          margin: 10px;

          background-color: #ccc;
        }
      }
    }

    .bp-l-b-only {
      height: calc(100% - 72px) !important;
    }

    .bp-l-f {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      height: 70px;

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px -5px 9px #0000001a;
      border: 1px solid #eeeeee;
      opacity: 1;

      button.btn {
        min-width: 140px;
        height: 40px;
        margin: 0;
      }
    }
  }

  .bp-r {
    display: flex;
    flex-flow: column;

    width: calc(100% - 360px);

    background: #f4f4f5 0% 0% no-repeat padding-box;
    overflow: auto;

    .bp-r-h,
    .bp-r-h1 {
      display: flex;
      flex-flow: row;

      height: 70px;
      padding: 35px 15px;

      label,
      div {
        margin: 0;

        align-self: center;
      }

      div.bp-r-h-options {
        display: flex;

        margin-left: auto;

        button.btn {
          min-width: 120px;
          height: 44px;
          margin: 0 5px;
          border-radius: 10px !important;
        }
      }
    }

    .bp-r-h {
      font: normal normal bold 20px/27px Graphik-Medium;
      letter-spacing: 0px;
      color: #0e0e0e;
    }

    .bp-r-h1 {
      font-size: 22px;
      font-family: "Graphik-Medium";
      border-bottom: 1px solid rgba(112, 112, 112, 0.54);
    }

    .bp-r-b,
    .bp-r-b-only {
      display: flex;
      flex-grow: 1;

      height: calc(100vh - 142px);
    }

    .bp-r-b-only {
      height: calc(100% - 72px) !important;
    }

    .bp-r-b-only.block {
      display: block !important;
    }
  }
}

/* common styles - when left menu is present - end */
/* common styles - when left menu is present */

/* modal-dialog styles - common across the application - start */
/* pages involved: MVPConfigurationDashboard, MVPBPConfiguration */
.add-new-mp {
  .modal-dialog {
    max-width: 80% !important;

    .mp {
      display: flex;
      flex-flow: row;

      .mp-l {
        display: flex;
        flex-flow: column;

        width: 400px;
        max-width: 400px;

        label {
          margin: 0;
        }
      }

      .mp-r {
        display: flex;
        flex-flow: column;

        flex-grow: 1;

        label {
          margin: 0;
        }
      }
    }
  }
}

.modal-dialog {
  max-width: 700px !important;

  .modal-content {
    border-radius: 6px !important;
    color: #0e0e0e;
    padding: 10px;

    .modal-header {
      border-bottom: 0px solid #dee2e6;
      font-family: "Graphik-Medium";

      .modal-title {
        font-size: 20px !important;
        font-weight: bold !important;
      }
    }

    .modal-body {
      font-size: 20px;
      font-family: "Graphik-Regular";

      label {
        font-size: 16px;
      }

      .each-dialog-content {
        .each-d-section {
          margin: 15px 0;

          .icon {
            width: 30px;
            height: 30px;
            padding: 5px;
            margin: 5px;
          }

          .icon.check {
            content: url("/img/prepare/check.svg");
          }
          .icon.uncheck {
            content: url("/img/prepare/uncheck.svg");
          }
        }
        .queue-header {
          display: flex;
          padding-bottom: 10px;
          div:not(:first-child) {
            flex-basis: 30%;
          }
          div:first-child {
            flex-basis: 10%;
          }
        }
        .queue-content {
          display: flex;
          border: 2px solid #c2c2c5;
          padding: 15px 0;
          border-radius: 8px;
          margin: 15px 0;

          .each-d-section .build_link {
            color: #007bff;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
              color: #ff6900;
            }
          }

          // :first-child  {
          //   width: 25%;
          //   padding-left: 25px;
          // } 
          // :last-child {
          //   width: 75%;
          //   word-wrap: break-word;
          // }
          div:not(:first-child) {
            padding-right: 15px;
            flex-basis: 30%;
            word-wrap: break-word;
          }
          div:first-child {
            padding-left: 15px;
            flex-basis: 10%;
            word-wrap: break-word;
          }
          .each-d-section {
            margin: 5px 0 !important;
          }
        }

      }
    }

    .modal-footer {
      border-top: 0px solid #dee2e6;
      font-family: "Graphik-Medium";

      .btn {
        font-size: 16px !important;
      }
    }
  }
}

.modal-dialog.printDialog.modal-dialog-centered {
  max-width: 60vw;
}

/* modal-dialog styles - common across the application - end */

/* jsoneditor styles - common across the application - start */
/* pages involved: MVPConfigurationDashboard > MVPJSONEditor, MVConfigurationUpdate > MVPJSONEditor, MVPBPConfiguration > MVPJSONEditor */
.jsoneditor {
  height: calc(100vh - 162px);

  .jsoneditor-outer {
    height: calc(100vh - 162px);
  }
}

.modal-dialog {
  .jsoneditor {
    // height: calc(100vh - 324px);
    // margin: 10px 0;

    .jsoneditor-outer {
      height: calc(100vh - 324px);

      textarea.jsoneditor-text {
        min-height: auto;
      }
    }
  }
}

/* jsoneditor styles - common across the application - end */

/* New UX design Styles */
.back-btn {
  color: #1a1e26;
  font-size: 16px !important;
  font-family: "Graphik-Regular" !important;
  text-align: left !important;
}

.btn-right-arrow {
  background: url("/right-arrow-white.svg") no-repeat;
  height: 20px;
  width: 10px;
}

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

ul.pagination li.page-item.active a.page-link {
  color: #445565 !important;
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}

ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 1rem;
}

ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}

.dropdown-latest {
  font-weight: bolder;
}

.dropdown-latest-text {
  color: #ff6900;
  margin-left: 10px;
}

.c-json-content .c-json-key,
.c-json-number,
.c-json-comma,
.c-json-string,
.c-json-boolean,
.c-json-pt,
.c-json-mark,
.c-json-p,
.c-json-outter {
  color: #000000 !important;
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.c-json-view {
  height: 79vh;
  overflow: scroll !important;
  text-indent: 25px;
  padding-top: 0px !important;
}

.diff-header {
  height: 35px;
  width: 100%;
  background-color: #3883fa;
  line-height: 3;
  font-size: 12px;
  color: #fff;
  padding-left: 20px;
}

.config-diff-header-right {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.modal-body {
  h4 {
    font: normal normal bold 18px/19px Graphik-Medium;
  }

  p {
    font: normal normal bold 15px/19px Graphik-Regular;
    margin-top: 20px;
  }
  span {
    // color: #666;
  }
}

.dropdown-item {
  // text-transform: capitalize !important;
}

// .filter-by-build-status a.dropdown-item:nth-child(2) {
//   display: none;
// }
.toast-body {
  background-color: #28a745;
  color: #fff;
}

.btstrp.dropdown .btn-group .dropdown-menu .dropdown-item {
  padding: 0px 10px !important;
  font: normal normal 700 15px/19px Graphik-Regular !important;
}

button:disabled,
button[disabled] {
  border-radius: 10px !important;
}

.btn-group {
  .btn {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.config-icons {
  width: 24px;
}

/***************Global Styles - common across the application Start******************/
.backIcon {
  margin: auto 10px;
  background: url(/img/back-arrow.svg) no-repeat;
  height: 22px;
  width: 36px;
  cursor: pointer;
}

.back-btn-heading {
  margin: auto 10px;
  font: normal normal bold 20px/28px Graphik-Medium;
  color: #1a1e26;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.border-botm {
  border: 1px solid #707070;
  opacity: 0.57;
}

.page-heading {
  font: normal normal bold 18px/26px Graphik-Medium;
  color: #0e0e0e;
}

.page-detail-heading {
  font: normal normal bold 15px/20px Graphik-Regular;
  color: #666666;
}

.label-text {
  font: normal normal normal 14px/18px Graphik-Regular;
  color: #666666;
  padding-top: 10px;
  padding-bottom: 5px;
}

.label-value {
  font: normal normal bold 14px/18px Graphik-Medium;
  color: #1a1e26;
}

.MuiTypography-body1,
.MuiButton-label {
  font-family: "Graphik-Regular" !important;
  font-size: 14px !important;
  color: #666 !important;
}

.overlay-text {
  font: normal normal normal 14px/18px Graphik-Medium !important;
  // background: #343E4A;
}

/***************Global Styles - common across the application End******************/

// checkbox styling
.form-check-input[type="checkbox"] {
  width: 17px;
  height: 17px;
  margin-left: -27px !important;
}

.MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
  font-size: 1rem !important;
}

// Multiselect dropdown styles
.cut-options-val .MuiInputBase-root {
  color: #666 !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: "Graphik-Regular" !important;
  min-width: 250px;
  height: 37px;

  .MuiSelect-select:focus {
    background: none;
  }
}

.MuiMenu-paper {
  border: 1px solid #9b9b9b59;
  // top: 263px !important;
}

.partsDialog {
  max-width: 700px !important;

  label {
    color: #0e0e0e;
    font-weight: 600;
    display: block;
  }

  input, textarea {
    background: #f4f4f5;
    border-radius: 6px;
    font-size: 16px;
    border: 0 none;
    padding: 10px 15px;
  }
}

.printQueueDialog {
  max-width: 60% !important;
  .modal-content {
    min-height: 320px !important;
  }

  label {
    color: #0e0e0e;
    font-weight: 600;
    display: block;
  }

  input, textarea {
    background: #f4f4f5;
    border-radius: 6px;
    font-size: 16px;
    border: 0 none;
    padding: 10px 15px;
  }
}

.drop-zone {
  width: 100%;
  height: 134px;
  border-radius: 6px;
  background: #f4f4f5;
  text-align: center;
  padding-top: 25px;
  color: #adadad;

  input {
    height: 112px;
    width: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.close-btn {
  background: url("/close.svg") no-repeat;
  opacity: 1;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.tick-btn {
  background: url("/img/tick.svg") no-repeat;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.remove-import {
  background: url("/remove.svg") no-repeat;
  width: 21px;
  height: 24px;
  float: right;
  cursor: pointer;
  align-self: center;
  margin-left: 10px;
}

.search-message {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // -ms-transform: translateX(-50%) translateY(-50%);
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  font-size: 20px;

  &.top50{
    top: 50%;
  }
}

:focus-visible {
  // outline: #ff6900 auto 1px !important;
}

.hint-msg {
  color: #606060;
  font-size: 16px;
}

/**************Slider Styles******************/
.slider-actions-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 35px;
  flex-direction: column;
}

.warning-popup-footer {
  .footer-btn {
    padding: 8px 50px !important;
  }
}

.recharts-cartesian-axis-tick {
  font: normal normal normal 12px/18px Graphik-Regular;
  color: #333333;
}

.recharts-legend-wrapper {
  bottom: -25px !important;
}

.recharts-label {
  font: normal normal normal 14px/18px Graphik-Regular;
  color: #333333;
}

.custom-tooltip {
  padding: 10px !important;
  background-color: #fff !important;
  font: normal normal normal 14px/18px Graphik-Regular;
  color: #333333;
}

.icon-small {
  width: 50px;
}
.icon-medium {
  width: 100px;
}

.progress-bar {
  background-color: #ff6900 !important;
  // &.bg-success{
  //   background-color: #ff6900!important;
  // }
}

input {
  background: #f4f4f5;
  border-radius: 6px;
  font-size: 16px;
  border: 0 none;
  padding: 10px 15px;
}

.dropdown-toggle {
  padding: 7px 10px !important;
  background: #f4f4f5 !important;
  color: #000000 !important;
  border: 1px solid #f4f4f5 !important;
  border-radius: 6px !important;
}

/***********Folder Page Styles*************/
.folder {
  .folder-name {
    font: normal normal bold 20px/28px Graphik-Medium;
    padding-bottom: 30px;
  }
}

.display-info {
  font: normal normal bold 13px/13px Graphik-Medium;
  color: #666666;
  margin-left: 100px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;

  label:nth-child(2) {
    margin-bottom: 0px;
  }
}

.added-files-list {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;

      input {
        width: 90%;
      }

      .progress-bar-import {
        display: flex;
        flex-direction: row;

        .progress {
          width: 89%;
          margin-top: 10px;
          margin-left: 6%;
        }

        .error-message {
          width: 89%;
          margin-top: 10px;
          margin-left: 6%;
        }
      }
    }
  }
}

/*******Config Modal Popup*******/
.primary-color {
  color: #ff6900;
}

.modal-heading {
  font-size: 24px !important;
  line-height: 40px;
}

.config-modal {
  .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 24px !important;
  }

  .plate-info {
    font: normal normal bold 20px/16px Graphik-Regular;
    margin: 10px 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(219, 219, 219);

    span {
      color: #ff6900;
    }
  }

  .part-info {
    overflow-x: auto;
    display: flex;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 25px;

    .build-parts {
      color: #ff6900;
      font: normal normal bold 14px/16px Graphik-Regular;
      border: 1px solid #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px !important;
      cursor: pointer;
      margin: 0 15px 10px 0;
      padding: 10px 20px;
      border: 2px solid #f4f4f4;
      width: fit-content;

      &:hover,
      &.active {
        border: 2px solid #ff6900;
      }

      .thumbnail-img {
        height: 45px;
        margin-right: 20px;
        align-items: center;
      }
    }
  }

  .tool-configuration-list li span:last-child {
    font-size: 16px;
  }
}

.data-format {
  font: normal normal normal 14px/18px Graphik-Regular;
}
.link-format {
  color: #ff6900;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.valign{
  display: flex;
  align-items: center;
  img{
    margin-right: 5px;
  }
}

.warning-popup>.tooltip-inner {
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #767373;
  color: #000000;
  font: normal normal normal 14px/18px Graphik-Regular;
  padding: 10px;
  p{
    padding: 5px;
    border-bottom: 1px solid #767373;
    margin: 0px;
  }
  p:last-child{
    border-bottom:0px solid #fff !important;
  }
}

.warning-popup>.tooltip-arrow {
background-color: #fff;
border: 0px solid #fff;
}
.warning-icon-wrapper{
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.48);
    -webkit-box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.48);
    padding: 5px;
}
.MuiInputLabel-formControl,.MuiInputBase-input {
  font: normal normal normal 16px/18px Graphik-Regular !important
}
.MuiTypography-body2 {
  font: normal normal normal 14px/18px Graphik-Regular !important;
}
.success-build-message{font: normal normal bold 20px/30px Graphik-Regular !important;}
.success-tick {
  background: url('/img/successtick.png') 50% no-repeat;
  background-size: 10%;
    height: 62px;
    width: 100%;
}
.modal-90w{
  min-width: 90% !important;
}
video::-webkit-media-controls-panel {
  display: flex !important;
  opacity: 1 !important;
}
.user-management {
  font-family: "Graphik-Regular";


  .user-management-grid {
    margin: 45px;
    display: flex;

      .active.user-grid-status {
        content: "";
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #4bb749;
        padding-right: 10px;
        display: inline-block;
        position: absolute;
        top: 30%;
      }
      .inActive.user-grid-status {
        content: "";
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #d30000;
        padding-right: 10px;
        display: inline-block;
        position: absolute;
        top: 30%;
      }

      .user-status-text {
        margin-left: 28px;
        text-transform: capitalize;    
        font-family: 'Graphik-Medium';
        
        &.active {
          color: #4bb749;
        }
        &.inActive {
          color: #d30000;
        }
      }

  }
  .create-user-btn {
    margin-left: auto;
  }
  .search-input {
    height: 80px;
    background: #fff;

    .user-search-form {
      padding: 15px 45px;

      .advanced-search-box {
        border-radius: 30px;
        height: 50px;
        background-color: #F4F4F4;
        font: normal normal 600 16px/21px Graphik-Regular;
        width: 100%;
        border-radius: 25px;
        height: 53px;
        color: #1A1E26;
        padding: 0 30px 0 50px;
        background: #F4F4F4 url("/search-icon.svg") no-repeat 20px 19px;
        position: relative;
        border: 2px solid #fff;

        &:focus,
        &:focus-visible {
          border: 2px solid #FF6900;
          box-shadow: none;
        }
      }
    }
  }
  .user-grid-actions {
    margin-top: 10px;
    .edit-icon {
      background: url(/edit.svg) no-repeat;
      width: 25px;
      height: 22px;
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
    }
    .delete-icon {
      background: url("/delete.svg") no-repeat;
      width: 25px;
      height: 22px;
      display: inline-block;
      cursor: pointer;
    }
  }
  

  .user-grid-actions.not-allowed {
    .edit-icon, .delete-icon {
      pointer-events: none;
    }
    cursor: not-allowed;
  }

  .modal-footer button {
    padding: 8px 40px !important;
  }
}

//Manage permissions

.manage-permission {
  margin: 20px 45px;
  padding-left: 15px;

  .roles-head {
    margin-bottom: 20px;
    font-family: "Graphik-Regular" !important;
    font-size: 18px;
  }

  .create-role-head {
    font-family: "Graphik-Semibold" !important;
    // padding-left: 15px; 
  }

  .roles-dropdown {
    // select {
    //   color: #080808 !important;
    //   background-color: #fff !important;
    //   border-radius: 6px !important;
    //   font-size: 14px !important;
    //   font-family: "Graphik-Regular" !important;
    //   min-width: 280px;
    //   height: 37px;
    //   padding: 10px;
    //   font-weight: 700;
    // }
    .roles-list-dropdown {
      width: 320px;
      height: 40px;
      background: #ffffff !important;
      color: black;
      border: 1px solid #a8aaab !important;
      text-align: center;
      display: flex;
      font-family: 'Graphik-Regular' !important;
      position: relative;
      padding: 0 0 0 20px !important;
      align-items: center;

      .dropdown-toggle-split {
        margin-left: auto;
        background: #ffffff !important;
        top: 0;
        position: relative;
        padding: 3px 21px !important;
        border: none !important;
      }
    }
  }

  .permission-grid {
    margin: 45px 0;
    display: flex;
    gap: 20px;

    .MuiDataGrid-root {
      flex-basis: 60% !important;
      flex: 0;
    }
  }
  .permission-actions {
    .btn-cancel {
      margin-right: 20px;
    }
  }
}

// Create user

.create-user-header {
  .header-section {
    .heading {
      display: inline-block;
      padding: 0;
      font: normal normal normal 18px Graphik-Medium;
      margin: auto 0;
    }
    .backIcon {
      margin: auto 10px;
      background: url(/img/back-arrow.svg) no-repeat;
      height: 22px;
      width: 36px;
      cursor: pointer;
    }
  }
}

.create-user-container, .create-role-container {
  h6 {    
    font-family: 'Graphik-Medium';
    font-size: 18px;
  }
  margin: 25px 45px;

  .profile-upload {
    .upload-section {
      .drop-zone {
        width: 280px;
        height: 134px;
        border-radius: 6px;
        background: #f4f4f5;
        text-align: center;
        margin-top: 25px;
        color: #adadad;
        border: 1px solid #ada1a1;

        .upload-text {
          margin-top: 10px;

          span.clickToUpload {
            color: #291a80;
            font-weight: 700;
          }
        }
      }
    }
  }

  .user-data-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;

    .role-name {
      width: 70%;
    }

    .disabled.email-id {
      .input-box {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .input-box {    
    height: 38px;
    border: 1px solid #b5aeae;
    background: #fff;
  }

  .input-box.required-error, .select-box.required-error {
    border: 1px solid #f80707 !important;
  }

  .client-dropdown-section {
    margin-top: 25px;
  }

  .client-dropdown, .role-dropdown, .permission-dropdown {
    // select {
    //   color: #080808 !important;
    //   background-color: #fff !important;
    //   border-radius: 6px !important;
    //   font-size: 14px !important;
    //   font-family: "Graphik-Regular" !important;
    //   min-width: 250px;
    //   height: 38px;
    //   padding: 10px;
    //   font-weight: 700;
    //   border: 1px solid #b5aeae;
    // }
    .roles-list-dropdown {
      width: 320px;
      height: 40px;
      background: #ffffff !important;
      color: black;
      border: 1px solid #a8aaab !important;
      text-align: center;
      display: flex;
      font-family: 'Graphik-Regular' !important;
      position: relative;
      padding: 0 0 0 20px !important;
      align-items: center;

      .dropdown-toggle-split {
        margin-left: auto;
        background: #ffffff !important;
        top: 0;
        position: relative;
        padding: 3px 21px !important;
        border: none !important;
      }
    }
  }


  .status-btn.tab-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 70px;
    max-height: 70px;

    .options.left-justify {
      border: 1px solid #ff6900;
      border-radius: 8px;
    }

    .flex-space {
      flex: 1 1 auto;
    }

    .tab {
      padding: 10px 15px;

      &.active {
        color: #ff6900;
        padding: 0;

        label {
          border-radius: 6px;
          background: #ff6900;
          padding: 14px 15px;
          color: #fff;
        }
      }

      label {
        display: inline-block;
        text-align: center;
        min-width: 70px;
        margin: auto;
        padding: 0;
        font: normal normal normal 16px Graphik-Medium;
        cursor: pointer;
      }
    }
  }
  .user-action-btn {
    margin-top: 45px;

    .btn-cancel {
      margin-right: 20px;
    }
  }
}

.build-grid-view.MuiDataGrid-root {
  margin-top: 35px !important;

  .MuiDataGrid-sortIcon  {
    color: #fff !important;
  }
}
.build-grid-view {
  .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    display: none;
  }
}
.plate-review-check-box{
  right: 1px;
  top: 1px;
}

span.mandate-error {
  color: #f80707;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.grid-switch {
  display: flex;
  align-items: center;
  span {
    font-size: 18px;
    font-weight: 700;
    margin-right: 10px;
  }

.slider {
  background-color: #ffffff2b;
  border-radius: 100px;
  padding: 1px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  align-items: center;
  position: relative;
  display: block;
  width: 51px;
  height: 29px;
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
        #22cc3f 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.slider::after {
  content: "";
  display: flex;
  top: 3.3px;
  left: 2px;
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  border-radius: 200px;
  position: absolute;
  box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
  transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  will-change: left, background-color;
}

.switch input[type="checkbox"]:checked + .slider {
  box-shadow: rgb(235 231 231 / 62%) 0px 0px 5px inset, #ff6900 0px 0px 0px 2px inset, #ff6900 0px 0px 0px 24px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.switch input[type="checkbox"]:checked + .slider::after {
  left: 24px;
}

.switch input[type="checkbox"] {
  display: none;
}

.icon.check {
  content: url("/img/prepare/check.svg");
}

.icon.uncheck {
  content: url("/img/prepare/uncheck.svg");
}
span.field {
	font: normal normal normal 16px Graphik-Regular;
	color: #0e0e0e;
}
}
.plate-review-check-box{
  right: 1px;
  top: 1px;
}
.warning-build-review{
  border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p{
      text-align: center;
      font: normal normal normal 18px Graphik-Regular;
    }
}
.printer-tool-configuration-list{
li{
  .progress{
    .bg-warning{background-color: #ff6900 !important;}
  }
}
}
p.notes{font: normal normal normal 17px Graphik-Regular; color: #ff6900;}
.display-thumbnail-images{
  img{
    margin-right: 10px;
    margin-bottom: 10px;
    filter: drop-shadow(0px 0px 7px #c8c6c6);}
  
}
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.image-preview {
  position: relative;
}
.image-preview img {
  max-width: 150px;
  max-height: 150px;
  margin-right: 4px;
}
.image-preview button {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border: none;
  cursor: pointer;
}
button {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}
.cancel-btn{
  position: absolute;
  right: 0px;
  top: 0px;
  background: white;
  border-radius: 0px 0px 0px 5px;
  cursor: pointer;
}


.role-permission-grid {
  width: 75%
}
