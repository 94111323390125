section.page {
	display: flex;
	flex-flow: column;
	height: calc(100vh - 90px);
	overflow: auto;
	background: #f4f4f5;

	>header {
		flex: 0 1 auto;
		height: 70px;
		max-height: 70px;
		padding: 0 45px;
		border-bottom: 1px solid #cecece;

		>* {
			height: inherit;
		}
	}

	>section {
		flex: 1 1 auto;
		overflow: auto;
	}

	>footer {
		flex: 0 1 40px;
	}
}

.options {
	display: flex;
	align-items: center;
	background: transparent;

	.info,
	.option {
		display: flex;
		align-items: center;

		label {
			display: inline-block;
			text-align: center;
			min-width: 70px;
			margin: auto;
			padding: 0;
			font: normal normal normal 20px Graphik-Medium;
			cursor: pointer;
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			width: 100px;
			height: 30px;
			padding: 0;
			margin: 0 10px;

			background: #ff6900 0% 0% no-repeat padding-box;
			color: white;
			border: 1px solid #ff6900;
			border-radius: 15px;
			box-shadow: none !important;
			cursor: pointer;
			font: normal normal 600 16px Graphik-Regular !important;

			img {
				margin: 0 !important;
			}

			span {
				margin: 0;
				font: normal bold 450 12px Graphik-Regular;
			}
		}

		button:not(:disabled):not(.disabled).active {
			background: #ff6900 0% 0% no-repeat padding-box;
			color: white;
			border: 1px solid #ff6900;
		}

		button:not(:disabled):not(.disabled).inactive {
			background: #ffffff 0% 0% no-repeat padding-box;
			color: #ff6900;
			border: 1px solid #ff6900;
			pointer-events: none;
		}

		span {
			cursor: pointer;
			background: transparent;
			opacity: 1;

			align-self: center;
			padding: 5px;
			margin: 5px;

			font: normal normal 450 12px Graphik-Regular;
		}

		img {
			cursor: pointer;
			background: transparent;
			opacity: 1;
		}

		.icon {
			width: 30px;
			height: 30px;
			padding: 5px;
			margin: 5px;
		}

		.icon.back {
			// content: url("/img/prepare/back.svg");
			content: url("/back-icon.svg") no-repeat;
		}

		.icon.download-in-progress {
			border-radius: 50%;
			content: url("/img/prepare/downloading.gif");
		}

		.icon.loading-complete {
			content: url("/img/prepare/complete.svg");
		}

		.icon.loading-in-progress {
			border-radius: 10px;
			content: url("/img/prepare/loader.svg");
		}

		.icon.back {
			content: url("/back-icon.svg");
			width: 20px;
			height: 24px;
			padding-right: 0;
		}

		.icon.check {
			content: url("/img/prepare/check.svg");
		}

		.icon.uncheck {
			content: url("/img/prepare/uncheck.svg");
		}

		.icon.more-check {
			content: url("/img/prepare/more-check.svg");
		}

		.icon.more-uncheck {
			content: url("/img/prepare/more-uncheck.svg");
		}

		.icon.add {
			content: url("/img/prepare/add.svg");
		}

		.icon.asc-sort {
			content: url("/descending.svg");
		}

		.icon.desc-sort {
			content: url("/ascending.svg");

			//   &:hover {
			// 	  content: url("/ascending-sort-hover.svg");
			//   }
		}

		.icon.list-view {
			content: url("/img/prepare/grid-view-icon.svg");
			width: 35px;
		}

		.icon.card-view {
			content: url("/img/prepare/list-view-icon.svg");
			width: 35px;
			//   &:hover {
			// 	  content: url("/list-view-icon-hover.svg");
			//   }
		}

		.icon.part {
			content: url("/part-thumbnail.svg");
		}

		.icon.plate {
			content: url("/plate-thumbnail.svg");
		}

		.icon.build {
			content: url("/build-thumbnail.svg");
		}

		.icon.cube {
			content: url("/img/prepare/cube.svg");
		}

		.icon.collapse-all {
			content: url("/img/prepare/collapse-all.svg");
		}

		.icon.expand-all {
			content: url("/img/prepare/expand-all.svg");
		}

		.icon.stack {
			content: url("/img/prepare/stack.svg");
		}

		.icon.download {
			content: url("/img/prepare/download.svg");
		}

		.icon.unarchive {
			content: url("/img/prepare/unarchive.svg");
		}

		.icon.archive {
			content: url("/img/prepare/archive-icon.svg");
		}

		.icon.side-preview {
			content: url("/img/prepare/side-preview-icon.svg");
		}

		.icon.delete {
			content: url("/img/prepare/delete.svg");
		}

		.icon.clear {
			content: url("/img/prepare/clear.svg");
		}

		.icon.up-arrow {
			content: url("/img/prepare/up-arrow.svg");
		}

		.icon.right-arrow {
			content: url("/img/prepare/right-arrow.svg");
		}

		.icon.down-arrow {
			content: url("/img/prepare/down-arrow.svg");
		}

		.icon.left-arrow {
			content: url("/img/prepare/left-arrow.svg");
		}

		.icon.settings {
			content: url("/img/prepare/settings.svg");
		}

		.icon.folder {
			content: url("/img/prepare/folder.svg");
		}

		.icon.copy {
			content: url("/copy-icon.svg");
		}
	}

	.info.download-status {
		justify-content: flex-end;
	}

	.option.filter-by-build-status {
		button {
			width: 150px;
			column-gap: 10px;
			padding-right: 10px;
			text-transform: capitalize;
		}
	}

	.option.add-to-plate {
		color: #ff6900;
		font-size: 28px;
		line-height: 15px;
		padding-right: 15px;

		button {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			flex-wrap: wrap !important;

			width: 50px !important;
			height: 20px !important;
			padding: 0 !important;
			margin: 5px 10px !important;

			background: #ff6900 0% 0% no-repeat padding-box !important;
			color: white !important;
			border: 1px solid #ff6900 !important;
			border-radius: 15px !important;
			box-shadow: none !important;
			cursor: pointer !important;

			font: normal normal 600 12px Graphik-Regular !important;

			span {
				padding: 0 !important;
				margin: 0 !important;

				cursor: pointer !important;

				font: normal normal 600 12px Graphik-Regular !important;
			}
		}
	}

	.option.search {
		width: 45%;
		input.search-box {
			width: 300px;
			height: 40px;

			box-shadow: none !important;

			background: white 0% 0% no-repeat padding-box;
			border: 1px solid #c5c5c5;
			border-radius: 12px;

			font: normal normal normal 14px OpenSans-Regular;
			color: #000000;
			padding-left: 35px !important;
		}

		.advanced-search-box,
		.search-box {
			font: normal normal 600 16px/21px Graphik-Regular;
			width: 100%;
			border-radius: 25px;
			height: 53px;
			color: #1A1E26;
			padding: 0 30px 0 50px;
			background: #F4F4F4 url("/search-icon.svg") no-repeat 20px 19px;
			position: relative;
			border: 2px solid #fff;

			&:focus,
			&:focus-visible {
				border: 2px solid #FF6900;
				box-shadow: none;
			}
		}

		.close-icon {
			background: url("/close.svg") no-repeat;
			content: "";
			width: 18px;
			height: 18px;
			z-index: 1;
			right: 45px;
			cursor: pointer;
			position: relative;
		}

		.advanced-search-box:not(:valid)~.close-icon {
			display: none;
		}

		input.search-box:disabled {
			background: lightgrey;
		}

		input.search-box:focus {
			border: 1px solid #ff6900;
			box-shadow: none;
		}

		// input.search-box::placeholder {
		// 	font: normal normal 450 12px OpenSans-Regular;
		// }
	}

	.option.collapse,
	.otpion.expand {
		transition-property: display;
	}

	.option.plate-configuration {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: stretch;
		margin: 10px 20px;

		>* {
			width: 100%;
			margin-top: 5px;
		}

		label {
			text-align: left;
			align-self: flex-start;
			color: #1F2A37;
			font: normal normal normal 16px Graphik-Regular;
		}

		#dropdown-basic-button {
			justify-content: flex-end;
			width: 100%;
			padding: 0 10px !important;
			margin: 0;
			background: #ffffff 0% 0% no-repeat padding-box !important;
			color: #666666!important;
			border: 1px solid #7b868e !important;
			border-radius: 4px !important;
		}
	}
}

.copyFolderPart .dropdown {
	#dropdown-basic-button {
	width: 295px;
	padding: 12px 10px !important;
	margin: 0;
	background: #ffffff 0% 0% no-repeat padding-box !important;
	color: #000!important;
	border: 1px solid #0d0d0e !important;
	border-radius: 8px !important;
}
.dropdown-menu.show {
	max-height: 400px;
	overflow-y: auto;
}
}

.options.left-justify {
	justify-content: flex-start;
}

.options.left-justify-plate {
	margin-left: 30px;
}

.options.right-justify {
	justify-content: flex-end;
}

header {
	.tab-bar {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 70px;
		max-height: 70px;

		.flex-space {
			flex: 1 1 auto;
		}

		.tab {
			margin-right: 40px;
   			padding: 10px 15px;

			&.active {
				color: #ff6900;
				padding: 0;

				label {
					border-radius: 6px;
					background: #ff6900;
					padding: 10px 15px;
					color: #fff;
				}

				// &::after {
				// 	content: "";
				// 	position: absolute;
				// 	left: 0;
				// 	right: 0;
				// 	bottom: 0;
				// 	width: 40px;
				// 	height: 7px;
				// 	margin: 0 auto;
				// 	background-color: #ff6900;
				// 	border-radius: 50% 50% 0 0;
				// }
			}

			label {
				display: inline-block;
				text-align: center;
				min-width: 70px;
				margin: auto;
				padding: 0;
				font: normal normal normal 18px Graphik-Medium;
				cursor: pointer;
			}
		}
	}

	.menu-bar {
		display: flex;
		flex-wrap: wrap;
		justify-content: right;
		align-items: center;
		//  height: 50px;
		// max-height: 50px;
		padding: 0px 40px;
		border-top: 1px solid #cecece;
		border-bottom: 1px solid #cecece;

		.flex-space {
			flex: 1 1 auto;
		}
	}
}


section.home.page,
.review-page,
section.dashboard-page {
	>section {
		dl {
			display: flex;
			flex-direction: column;

			padding: 20px;
			margin: 0px;

			// border-bottom: 1px solid grey;

			dt {
				display: flex;
				align-items: center;

				position: sticky;
				top: 0;
				height: 50px;
				// margin-left: 20px;

				background: white;
				// border: 1px solid grey;
				border-radius: 5px;
				z-index: 3;

				label {
					flex: 0 1 auto;

					padding: 0;
					margin: 0;

					font: normal normal normal 16px Graphik-Regular;
					color: #0e0e0e;
				}

				.options {
					flex: 1 1 auto;

					display: flex;
					align-items: center;
				}
			}

			dd {
				display: flex;
				flex-wrap: wrap;

				margin-left: 20px;
				margin-bottom: 20px;

				.folder-card,
				.individual-item-card {
					min-height: 80px;
					flex-direction: row !important;
					align-items: center;

					.display-card-row {
						width: 100%;
						align-items: center;

						.display-card-sub-header {
							padding: 0px !important;
						}
					}
				}
			}
		}

		dl {
			grid-row-gap: 20px !important;
			row-gap: 20px !important;

			dd:not(.dashboard-selected-parts) {
				row-gap: 20px !important;
				column-gap: 2.5% !important;
			}

			.display-card {
				display: flex !important;
				flex-direction: column !important;
				width: 323px;

				background: #ffffff 0% 0% no-repeat padding-box;
				border: 1px solid #ffffff;
				box-shadow: 0px 3px 6px #00000029;
				border-radius: 5px !important;
				cursor: pointer;

				&.is-added-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&.is-selected-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&:hover {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				.preview-img {
					display: flex;
					background: #d8dcd6;
					justify-content: center;
					margin: 5px 10px;
				}

				.display-card-part-icon {
					width: auto;
					height: 125px;
					padding: 10px 0px;
					margin-bottom: 10px;
				}

				.display-card-row {
					display: flex;
					// min-height: 40px !important;
					padding-top: 10px;

					.display-card-header {
						flex-grow: 1;

						margin: 0;
						padding: 5px 10px;

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding-left: 10px;
							font: normal normal bold 14px/16px Graphik-Semibold;
							letter-spacing: 0px;
							color: #353535;
							word-break: break-all;

							@media only screen and (width : 1536px) {
								font: normal normal bold 12px/16px Graphik-Semibold;

							}
						}
					}

					.display-card-sub-header {
						flex-grow: 1;
						margin: 0;
						width: 100%;

						&.status-display {
							display: flex;
							justify-content: space-between;
							vertical-align: middle;
							align-items: center;
							padding: 5px 0;
						}

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding-left: 20px;

							font: normal normal 450 12px Graphik-Regular;
							word-break: break-all;
						}

						.label-part {
							margin-top: 15px;
							font: normal normal 450 12px Graphik-Regular;
							word-break: break-all;
							padding-left: 15px;
						}
					}

					.display-card-more-action {
						#dropdown-basic {
							padding: 0 !important;

							background: transparent !important;
							border: transparent !important;
							box-shadow: none !important;

							&:after {
								content: unset;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}

					.display-card-more-action-part {
						#dropdown-basic {
							background: transparent !important;
							box-shadow: none !important;
							padding: 0 !important;
							float: right;

							&:after {
								content: unset;
								display: inline-block;
							}
						}
					}
				}
			}
		}

		dl.grid-view {
			grid-row-gap: 0;
			row-gap: 0;

			dd {
				row-gap: 0;
				column-gap: 0;
			}

			.display-list {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				width: 100%;
				background: #FFFFFF 0% 0% no-repeat padding-box;
				cursor: pointer;
				height: 50px;
				//   margin-bottom: 30px;
				border-radius: 6px;

				&:first-child {
					background: none;
					height: auto;
					margin-bottom: 10px;
					pointer-events: none;
				}

				&:last-child {
					border-bottom: 0;
				}

				// &:nth-child(odd) {
				// 	background: #F8F8F8 0% 0% no-repeat padding-box;
				// }

				&.is-added-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&.is-selected-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&:hover {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					// border: 1px solid #ff6900;
				}

				.display-list-row {
					display: flex;
					width: 100%;
					justify-content: flex-start;

					.display-list-header {
						flex-basis: 14.28%;
						flex-grow: 0;
						flex-shrink: 0;
						margin: 0;
						padding: 10px 0;

						&:first-child {
							flex-basis: 4%;
						}

						&:last-child {
							flex-basis: 5%;
						}

						&:nth-child(2) {
							flex-basis: 33%;
						}

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding: 0 10px;
							font: normal normal bold 14px/16px Graphik-Semibold;
							letter-spacing: 0px;
							color: #353535;
							word-break: break-all;
						}
					}

					.display-list-sub-header {
						flex-basis: 14.28%;
						flex-grow: 0;
						flex-shrink: 0;
						margin: 0;
						padding: 10px 0;
						display: flex;
						align-items: center;

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding: 0 10px;
							font: normal normal 450 14px Graphik-Regular;
							word-break: break-all;
							color: #808080;
						}

						&:first-child {
							flex-basis: 4%;
						}

						&:last-child {
							flex-basis: 5%;
						}

						&:nth-child(2) {
							flex-basis: 33%;

							label {
								font-weight: bold;
								color: #000;
							}
						}
					}

					.display-list-more-action {
						#dropdown-basic {
							padding: 0;

							background: transparent !important;
							border: transparent !important;
							box-shadow: none !important;

							&:after {
								content: unset;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}
}

.option.expand,
.option.collapse {
	height: 50px;

	label {
		min-width: auto;
		text-align: left;
	}
}

.option.more-uncheck {
	z-index: 2;
}

.option.settings {
	button {
		width: auto;
		height: auto;
		margin: 0;
		padding: 0;
		background: none !important;
		border: none !important;
		box-shadow: none !important;
	}
}

.options {
	.btn-group {
		label {
			width: 120px;
			height: 30px;
			line-height: 30px;
			margin: 10px 0;
			padding: 0 10px;

			background: #ffffff 0% 0% no-repeat padding-box;
			color: #ff6900;
			border: 1px solid #ff6900;
			border-radius: 15px;

			&::nth-child(odd) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&::nth-child(even) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&:not(:disabled):not(.disabled).active {
				background: #ff6900 0% 0% no-repeat padding-box;
				color: #ffffff;
				border: 1px solid #ff6900;
			}
		}

		input[type="radio"] {
			display: none;
		}
	}
	.search-icons {
		width: 50%;
		display: flex;
		justify-content: flex-end;
		font: 14px Graphik-Regular;
		.option{
			padding-left: 25px;
		}
	}
}

.toggle-btn-container {
	flex: 1;
	margin-left: 10px;
	display: flex;
	align-items: center;
}

.text-ellipsis-second-line {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.capitalise-text {
	text-transform: capitalize;
	font: normal normal normal 13px Graphik-Regular;
}

.build-card-view {
	padding: 10px 0;

	&.container {
		height: 165px !important;
		margin: 0;
	}

	.build-preview-img {
		display: grid;
		float: left;
		width: 165px;
	}
}

.build-card-view,
.display-list-sub-header {

	svg,
	img {
		margin-left: 10px;
		width: 25px;
	}
}

.plate-name {
	margin-left: 20px !important;
}

.margin-t-10 {
	margin-top: 10px;
}

.scrollable-content {
	overflow-y: auto;
	background: #f4f4f5;
	height: calc(100vh - 230px);
	overflow-x: hidden;
}

@media only screen and (min-width: 1280px) and (max-width: 1534px) {
	section.home.page {
		>section {
			dl {
				.display-card {
					// width: 323px !important;
					// width: calc(100% - 82%) !important;
					width: 23% !important;
				}
			}
		}
	}

	section.page {
		.dashboard-page {
			.display-card {
				.img-col {
					.thumbnail-img {
						max-height: 121px;
						max-width: 200px;
					}
				}

				.display-card-row {
					.display-card-header {
						label {
							width: 252px;
						}
					}
				}
			}
		}
	}

}

@media only screen and (min-width: 1536px) and (max-width: 2048px) {
	section.page .dashboard-page .scrollable-content .display-card {
		width: 360px !important;
	}

	.option.filter-by-build-status {
		button {
			width: 150px;
			column-gap: 10px;
			padding-right: 10px;
			text-transform: capitalize;
		}
	}

	.option.add-to-plate {
		button {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			flex-wrap: wrap !important;

			width: 50px !important;
			height: 20px !important;
			padding: 0 !important;
			margin: 5px 10px !important;

			background: #ff6900 0% 0% no-repeat padding-box !important;
			color: white !important;
			border: 1px solid #ff6900 !important;
			border-radius: 15px !important;
			box-shadow: none !important;
			cursor: pointer !important;

			font: normal normal 600 12px Graphik-Regular !important;

			span {
				padding: 0 !important;
				margin: 0 !important;

				cursor: pointer !important;

				font: normal normal 600 12px Graphik-Regular !important;
			}
		}
	}

	.option.search {
		input.search-box {
			width: 300px;
			height: 40px;

			box-shadow: none !important;

			background: white 0% 0% no-repeat padding-box;
			border: 1px solid #c5c5c5;
			border-radius: 12px;

			font: normal normal normal 12px Graphik-Regular;
			color: #000000;
			padding-left: 35px !important;
		}

		input.search-box:disabled {
			background: lightgrey;
		}

		input.search-box:focus {
			border: 1px solid #ff6900;
		}

		// input.search-box::placeholder {
		// 	font: normal normal 450 12px Graphik-Regular;
		// }
	}

	.option.collapse,
	.otpion.expand {
		transition-property: display;
	}

	.option.plate-configuration {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: stretch;
		margin: 10px 20px;

		>* {
			width: 100%;
			margin-top: 5px;
		}

		label {
			text-align: left;
			align-self: flex-start;
			color: #1F2A37;
			font: normal normal normal 16px Graphik-Regular;
		}

		#dropdown-basic-button {
			justify-content: flex-end;
			width: 100%;
			padding: 0 10px !important;
			margin: 0;
			background: #ffffff 0% 0% no-repeat padding-box !important;
			color: #666666!important;
			border: 1px solid #7b868e !important;
			border-radius: 4px !important;
		}
	}
}

@media only screen and (width: 1280px) {
	.plate-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 400px;
		text-align: left !important;
	}
}
@media only screen and (max-width: 1280px) {
	
	section.page .plates-page {
		zoom: 70%;
	}
	.display-info {
		margin-left: 50px !important;
	}
	section.home.page > section dl .display-card, .review-page > section dl .display-card {
		// width: 323px !important;
		width: calc(100% - 77%);
	}
	.options .search-icons .option.cursor-pointer, .topNavSection .logo, .topNavSection .separator, nav {
		display: none;
	}
	section.page > header, header .tab-bar {
		max-height: 100px !important;
		height: 100px;
	}
	button.btn.btn-secondary {
		padding: 7px 10px !important;
		font-size: 14px !important;
	}
	section.home.page > section dl dd {
		justify-content: center !important;
	}
	section.page .dashboard-page .display-card .display-card-row .display-card-header label {
		width: 230px !important;
	}
}

.options.left-justify {
	justify-content: flex-start;
}

.options.left-justify-plate {
	margin-left: 30px;
}

.options.right-justify {
	justify-content: flex-end;
}

.part-indicate-icon, .build-indicate-icon, .plate-indicate-icon {
	right: 8px;
	font-family: 'Graphik-Regular';
	font-weight: 600;
	.icon-svg {
		border: 1px solid #bec1c4;
		border-radius: 5px;
		padding: 5px 5px 2px 5px;
		background: #ececec;
		margin-left: 0 !important;
		display: flex;
	}
}

header {
	.tab-bar {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 70px;
		max-height: 70px;

		.flex-space {
			flex: 1 1 auto;
		}

		.tab {
			margin-right: 40px;
   			padding: 10px 15px;

			&.active {
				color: #ff6900;
				padding: 0;

				label {
					border-radius: 6px;
					background: #ff6900;
					padding: 10px 15px;
					color: #fff;
				}

				// &::after {
				// 	content: "";
				// 	position: absolute;
				// 	left: 0;
				// 	right: 0;
				// 	bottom: 0;
				// 	width: 40px;
				// 	height: 7px;
				// 	margin: 0 auto;
				// 	background-color: #ff6900;
				// 	border-radius: 50% 50% 0 0;
				// }
			}

			label {
				display: inline-block;
				text-align: center;
				min-width: 70px;
				margin: auto;
				padding: 0;
				font: normal normal normal 18px Graphik-Medium;
				cursor: pointer;
			}
		}
	}

	.menu-bar {
		display: flex;
		flex-wrap: wrap;
		justify-content: right;
		align-items: center;
		//  height: 50px;
		// max-height: 50px;
		padding: 0px 40px;
		border-top: 1px solid #cecece;
		border-bottom: 1px solid #cecece;

		.flex-space {
			flex: 1 1 auto;
		}
	}
}

header {
	// height: 70px;
	// max-height: 70px;
	position: sticky;
	top: 0px;
	z-index: 10;
	background: #ffff;

	.menu.bar {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 70px;
		max-height: 70px;
		// padding: 0 30px;
		// border-top: 1px solid grey;
		// border-bottom: 1px solid grey;
		// justify-content: right;

		.flex-space {
			flex: 1 1 auto;
		}
	}
}

section.home.page,
.review-page {
	>section {
		dl {
			display: flex !important;
			flex-direction: column !important;

			// padding: 20px !important;
			margin: 0px !important;

			// border-bottom: 1px solid grey;

			dt {
				display: flex !important;
				align-items: center !important;

				position: sticky !important;
				top: 0 !important;
				height: 50px !important;
				// margin-left: 20px !important;

				background: white !important;
				// border: 1px solid grey;
				border-radius: 5px !important;
				z-index: 3 !important;

				label {
					flex: 0 1 auto !important;

					padding: 0 !important;
					margin: 0 !important;

					font: normal normal normal 16px Graphik-Regular;
					color: #0e0e0e;
				}

				.options {
					flex: 1 1 auto !important;

					display: flex !important;
					align-items: center !important;
				}
			}

			dd {
				display: flex !important;
				flex-wrap: wrap !important;
				gap: 15px;

				// margin-left: 20px !important;
				margin-bottom: 20px !important;

				.folder-card,
				.individual-item-card {
					min-height: 80px !important;
					flex-direction: row !important;
					align-items: center !important;

					.display-card-row {
						width: 100% !important;
						align-items: center !important;

						.display-card-sub-header {
							padding: 0px !important;
						}
					}
				}
			}
		}

		dl {
			grid-row-gap: 20px !important;
			row-gap: 20px !important;

			dd:not(.dashboard-selected-parts) {
				row-gap: 20px !important;
				column-gap: 20px !important;
			}

			.display-card {
				display: flex !important;
				flex-direction: column !important;
				padding: 10px 0px;

        width: 323px;
		@media only screen and (min-width: 1536px) and (max-width: 1919px) {
			// width: 323px !important;
			// width: calc(100% - 77%);
		}



				background: #ffffff 0% 0% no-repeat padding-box;
				border: 1px solid #ffffff;
				box-shadow: 0px 3px 6px #00000029;
				border-radius: 5px !important;
				cursor: pointer;

				&.is-added-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&.is-selected-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&:hover {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				.preview-img {
					display: flex;
					background: #d8dcd6;
					justify-content: center;
					margin: 5px 10px;
				}

				.display-card-part-icon {
					width: auto;
					height: 125px;
					padding: 10px 0px;
					margin-bottom: 10px;
				}

				.display-card-row {
					display: flex;
					min-height: 40px;

					.display-card-header {
						flex-grow: 1;

						margin: 0;
						padding: 5px 10px;

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding-left: 18px;
							font: normal normal bold 14px/16px Graphik-Semibold;
							letter-spacing: 0px;
							color: #ff6900;
							word-break: break-all;
						}
					}

					.display-card-sub-header {
						flex-grow: 1;
						margin: 0;
						width: 100%;

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding-left: 20px;

							font: normal normal 450 12px Graphik-Regular;
							word-break: break-all;
						}

						.build-info {
							width: 100%;
							padding-right: 20px;
							display: flex !important;
							flex-direction: row !important;
							justify-content: space-between !important;
						}

						.label-part {
							margin-top: 15px;
							font: normal normal 450 12px Graphik-Regular;
							word-break: break-all;
							padding-left: 15px;
						}
					}

					.display-card-more-action {
						#dropdown-basic {
							padding: 0 !important;

							background: transparent !important;
							border: transparent !important;
							box-shadow: none !important;

							&:after {
								content: unset;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}

					.display-card-more-action-part {
						#dropdown-basic {
							background: transparent !important;
							box-shadow: none !important;
							padding: 0 !important;
							float: right;

							&:after {
								content: unset;
								display: inline-block;
							}
						}
					}
				}
			}
		}

		dl.grid-view {
			grid-row-gap: 0;
			row-gap: 0;

			dd {
				row-gap: 0;
				column-gap: 0;
			}

			.display-list {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				width: 100%;
				background: #ffffff 0% 0% no-repeat padding-box;
				cursor: pointer;
				height: 50px;
				margin-bottom: 0px;
				border-radius: 6px;

				&:first-child {
					background: none;
					height: auto;
					margin-bottom: 10px;
					pointer-events: none;
				}

				&:last-child {
					border-bottom: 0;
				}

				// &:nth-child(odd) {
				// 	background: #F8F8F8 0% 0% no-repeat padding-box;
				// }

				&.is-added-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&.is-selected-true {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border: 1px solid #ff6900;
				}

				&:hover {
					background: #fbf6f2 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					// border: 1px solid #ff6900;
				}

				.display-list-row {
					display: flex;
					width: 100%;
					justify-content: flex-start;

					.display-list-header {
						flex-basis: 14.28%;
						flex-grow: 0;
						flex-shrink: 0;
						margin: 0;
						padding: 10px 0;

						&:first-child {
							flex-basis: 4%;
						}

						&:last-child {
							flex-basis: 5%;
						}

						&:nth-child(2) {
							flex-basis: 33%;
						}

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding: 0 10px;
							font: normal normal bold 14px/16px Graphik-Semibold;
							letter-spacing: 0px;
							color: #353535;
							word-break: break-all;
						}
					}

					.display-list-sub-header {
						flex-basis: 14.28%;
						flex-grow: 0;
						flex-shrink: 0;
						margin: 0;
						padding: 10px 0;
						display: flex;
						align-items: center;

						label {
							display: block;
							text-align: left;
							margin: 0;
							padding: 0 10px;
							font: normal normal 450 14px Graphik-Regular;
							word-break: break-all;
							color: #808080;
						}

						&:first-child {
							flex-basis: 4%;
						}

						&:last-child {
							flex-basis: 5%;
						}

						&:nth-child(2) {
							flex-basis: 33%;

							label {
								font-weight: bold;
								color: #000;
							}
						}
					}

					.display-list-more-action {
						#dropdown-basic {
							padding: 0;

							background: transparent !important;
							border: transparent !important;
							box-shadow: none !important;

							&:after {
								content: unset;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}
}

.option.expand,
.option.collapse {
	height: 50px;

	label {
		min-width: auto;
		text-align: left;
	}
}

.option.more-uncheck {
	z-index: 2;
}

.option.settings {
	button {
		width: auto;
		height: auto;
		margin: 0;
		padding: 0;
		background: none !important;
		border: none !important;
		box-shadow: none !important;
	}
}

.options {
	.btn-group {
		label {
			width: 120px;
			height: 30px;
			line-height: 30px;
			margin: 10px 0;
			padding: 0 10px;

			background: #ffffff 0% 0% no-repeat padding-box;
			color: #ff6900;
			border: 1px solid #ff6900;
			border-radius: 15px;

			&::nth-child(odd) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&::nth-child(even) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&:not(:disabled):not(.disabled).active {
				background: #ff6900 0% 0% no-repeat padding-box;
				color: #ffffff;
				border: 1px solid #ff6900;
			}
		}

		input[type="radio"] {
			display: none;
		}
	}
}

.toggle-btn-container {
	flex: 1;
	margin-left: 10px;
	display: flex;
	align-items: center;
}

.text-ellipsis-second-line {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.capitalise-text {
	text-transform: capitalize;
	font: normal normal normal 13px Graphik-Regular;
}

.build-card-view {

	svg,
	img {
		margin-left: 10px;
		width: 25px;
	}
}

.plate-name {
	margin-left: 20px !important;
}

.margin-t-10 {
	margin-top: 10px;
}



.tooltip-link {
	color: #ff6900;
	font: normal normal 13px Graphik-Medium !important;
	margin-left: 25px !important;
	width: fit-content;
}

.plate-name-parts-card {
	color: #ff6900;
	font: normal normal 13px Graphik-Medium !important;
}

.popover-plate-name {
	-webkit-box-shadow: -1px 2px 24px -7px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: -1px 2px 24px -7px rgba(0, 0, 0, 0.5);
	box-shadow: -1px 2px 24px -7px rgba(0, 0, 0, 0.5);
	background-color: #fff;
	padding: 15px;
}

.popover-plate-header {
	font: normal normal 13px Graphik-Medium !important;
}

.border-b {
	border-bottom: 1px solid #cecece;
}
.clear-serach-button {
	width: 150px;
	line-height: 2px;
	display: flex;
	align-self: center;
	margin-top: 10px;
	margin: 10px;
	color: #ff6900;
}
.no-result-found{
	transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    text-align: center;
	font: normal normal normal 16px/18px Graphik-Regular;
}
.no-image-found{
	position: relative;
    text-align: center;
    font: bold normal normal 18px / 22px Graphik-Regular;
}

.custom-notification-class {
	.total-count {
		padding: 10px;
		border-bottom: 0.7px solid #fff;
	}
	.notDeleted-count, .deleted-count {
		padding: 0 20px;
		text-decoration: underline;
		margin-top: 20px; 
	}
	.deleted-count {
		border-right: 1px solid #fff;
	}
}

.batch-delete-result-modal {
	.main-list, .deleted-list, .not-deleted-list {
		padding: 25px 0;
		border-top: 1px solid #000;
		gap: 15px;
		// border-bottom: 1px solid #000;
	}
	.deleted-list, .not-deleted-list {
		padding: 15px 0;
		border-top: 1px solid #000;
		gap: 15px;
		// border-bottom: 1px solid #000;
	}
	.deleted-link span {
		background: url(/completed.svg) no-repeat;
		height: 30px;
		width: 35px;
		display: inline-block;
		margin-right: 10px;
		background-size: 100% 100%;
	}
	.not-deleted-link span {
		background: url(/img/warning.svg) no-repeat;
		height: 30px;
		width: 45px;
		display: inline-block;
	}
	.deleted-link, .not-deleted-link {
		font-size: 20px;
		font-weight: 500;
		cursor: pointer;
		font-family: 'Graphik-Regular';
		&:hover {
			color: #ff6900;
		}
	}
	.deleted-list, .not-deleted-list {
		font-family: 'Graphik-Regular';
		border-bottom: none;

		&:not(:first-child){
			border-top: 1px solid #000;
		}
	}
	.not-deleted-list .warning {
		background: url(/img/warning.svg) no-repeat;
		height: 30px;
		width: 45px;
		display: inline-block;
	}
	.not-deleted-list .warning-filename {
		word-break: break-word;
		text-wrap: wrap;
	}
	.not-deleted-list .warning-message {
		font: normal normal bold 17px / 19px Graphik-Regular;
	}
	.not-deleted-list .d-flex {
		gap: 10px;
	}
	.deleted-list .completed {
		background: url(/completed.svg) no-repeat;
		height: 30px;
		width: 35px;
		display: inline-block;
		margin-right: 10px;
		background-size: 100% 100%;
	}
	.back-icon {
        margin: auto 10px;
        background: url(/img/back-arrow.svg) no-repeat;
        height: 22px;
        width: 36px;
        cursor: pointer;
	}
	.list-heading {
		margin-bottom: 25px;
	}
}
.delete-result-modal {
	// width: 400px;
	.modal-content .modal-body{
		max-height: 650px;
		overflow-y: auto;
		padding: 0 1rem;
	}
}
.batch-delete-error-popup.notification-error {
	background-color: #1f2a37;
	&::before {
		top: 30% !important;
	}
}
.notification-container {
	width: auto !important;
}

/* selected parts list view grid designs */

.list-view-selected-parts.display-parts-list {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		background: #374352 0% 0% no-repeat padding-box;
		cursor: pointer;
		height: 56px;
		margin-bottom: 0px;
		border-radius: 6px;

		// &:first-child {
		// 	background: none;
		// 	height: auto;
		// 	margin-bottom: 10px;
		// 	pointer-events: none;
		// }

		// &:last-child {
		// 	border-bottom: 0;
		// }

		// &:nth-child(odd) {
		// 	background: #F8F8F8 0% 0% no-repeat padding-box;
		// }

		// &.is-added-true {
		// 	background: #fbf6f2 0% 0% no-repeat padding-box;
		// 	box-shadow: 0px 3px 6px #00000029;
		// 	border: 1px solid #ff6900;
		// }

		// &.is-selected-true {
		// 	background: #fbf6f2 0% 0% no-repeat padding-box;
		// 	box-shadow: 0px 3px 6px #00000029;
		// 	border: 1px solid #ff6900;
		// }

		.display-list-row {
			display: flex;
			width: 100%;
			justify-content: flex-start;

			.display-list-header {   
				align-items: center;
				display: flex;
				flex-basis: 14%;
				flex-grow: 0;
				flex-shrink: 0;
				margin: 0;
				padding: 10px 0;

				&:first-child {
					flex-basis: 3%;
				}

				// &:last-child {
				// 	flex-basis: 5%;
				// }

				&:nth-child(2), &:nth-child(6) {
					flex-basis: 8%;
				}
				&:nth-child(3) {
					flex-basis: 21%;
				}
				&:nth-child(5) {
					flex-basis: 19%;
				}

				label {
					display: block;
					text-align: left;
					height: 20px;
					margin: 0;
					padding: 0 15px;
					font-family: 'Graphik-Regular';
					font-size: 16px;
					letter-spacing: 0px;
					color: #fff;
					word-break: break-all;
					border-left: 2px solid #fff;
				}

				
			}

			.display-list-more-action {
				#dropdown-basic {
					padding: 0;

					background: transparent !important;
					border: transparent !important;
					box-shadow: none !important;

					&:after {
						content: unset;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
}

.selected-parts-row {
	display: flex;
	height: 56px;
	width: 100%;
	align-items: center;
	background-color: rgba(63, 81, 181, 0.12);

	.selected-part-item {
		// flex-basis: 14%;
		.mui-checked-icon {
			width: 30px;
			height: 30px;
			padding: 3px;
			margin: 5px;
			cursor: pointer;
		}

		&:nth-child(1) {
			flex-basis: 3%;
		}

		&:nth-child(2), &:nth-child(6) {
			flex-basis: 9%;
		}
		&:nth-child(3) {
			flex-basis: 21%;
		}
		&:nth-child(4) {
			flex-basis: 14%;
		}
		&:nth-child(5) {
			flex-basis: 19%;
		}
		&:nth-child(7) {
			flex-basis: 13%;
		}
	}
}