section.page {
  .plates-page {
    display: flex;
    justify-content: space-between;
    height: 90px;
    padding: 0 45px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 12;
    position: relative;
  }
  > section.view.plate {
    dl {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      margin: 20px;

      //border: 1px solid grey;
      border-radius: 5px;

      dt {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        height: 50px;
        padding-left: 10px;
        padding-right: 10px;
        // border-bottom: 1px solid grey;
        border-radius: inherit;
        background: white;
        z-index: 2;
        width: 95.5%;

        label {
          flex: 0 1 auto;

          padding: 0;
          margin: 0;

          font: normal normal normal 16px Graphik-Regular;
          color: #0e0e0e;
        }

        .options {
          flex: 1 1 auto;

          display: flex;
          align-items: center;
        }
      }

      dd {
        display: flex;
        flex-wrap: wrap;
        row-gap: 25px;
        column-gap: 2.5%;

        // padding-left: 10px;
        margin-left: 10px;
        margin-bottom: 20px;

        .display-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 95.5%!important;

          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 5px;
          cursor: pointer;
          border: 2px solid #fff;

          &.is-added-true {
            background: #ffe5d1 0% 0% no-repeat padding-box;
            border: 2px solid #ff6900;
          }

          &.is-selected-true {
            border: 2px solid #ff6900;
          }

          &:hover {
            border: 2px solid #ff6900;
          }

          .display-card-row {
            display: flex;
            min-height: 40px;
            padding-top: 0!important;

            .display-card-header {
              flex-grow: 1;

              margin: 0;
              padding: 5px 10px;

              label {
                margin: 0;
                padding-left: 10px;

                font: normal normal 600 14px Graphik-Regular;
                word-break: break-all;
              }
            }

            .display-card-sub-header {
              flex-grow: 1;
              margin: 0;
              padding: 5px 10px;
              label {
                margin: 0;
                padding-left: 10px;
                font: normal normal 450 12px Graphik-Regular;
                word-break: break-all;
              }
              .build-status{
                font-size: 12px;
              }
            }

            .display-card-more-action {
              #dropdown-basic {
                padding: 0;

                background: transparent !important;
                border: transparent !important;
                box-shadow: none !important;

                &:after {
                  content: unset;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }

  > section.view.plate {
    display: flex;

    .left-menu {
      width: 563px;
      > * {
        height: inherit;
      }
      .display-parts {
        overflow: auto;
        height: 85%;
        padding-left: 45px;
      }
      header {
        padding-left: 45px;
      }
        .options .option.search input.search-box {
            border: 0 none;
            border-radius: 6px;
            // margin-left: 20px;
            // width: 370px;
            min-width: 375px;
            background: #F4F4F4  url("/search-icon.svg") no-repeat 10px 13px;
            border: 2px solid #fff;
            padding-left: 49px!important;
            &:focus {
                border: 2px solid #FF6900;
            }
        }
    }

    .right-view {
      display: flex;
      flex: 1;
      box-shadow: 0px 0px 6px #00000029;
      z-index: 10;
      &.preview-controls
      {
        .modePickerContainer {
          display: none;
        }
      }
    }
  }
.action-items{
  left: -4rem;
  position: relative;
  .pattern-view {
    background: url("/img/pattern.svg") no-repeat;
    width: 42px;
    height: 42px;
    cursor: pointer;
    margin-top: 16px;
    &:hover, &.active-btn {
      background: url("/img/pattern-active.svg") no-repeat;
    }
    }
}
.pattern-matrix {
  width: 230px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  background: #fff;
  top: 93px;
  position: absolute;
  padding: 20px;
  left: 36rem;
  .heading-actions {
    border-bottom: 1px solid #F4F4F5;
  }
  h6 {
    font-size: 18px;
    color: #FF6900;
  }
  label, input {
    font: normal normal normal 16px Graphik-Regular;
    padding-top: 20px;
    width: 195px;
  }
}
  
}
