.bundlesPage {
    display: flex;
    height: 100vh;
}
.bundlesPage .sideBarContainer {
    min-width: 270px;
    width: 270px;
}
.operations-options {
    display: none !important;
    overflow: auto;
    max-height: 40%;
    padding: 15px;
    margin-left: 264px;
    z-index: 999;
    position: absolute;
    top: 99px;
    min-width: 250px;
    background: #ddd;
    opacity: 0.8;
}
.operations-options.true {
    display: block !important;
}
.operations:hover .operations-options{
    display: block !important;
}
.operations:hover .option-header{
    font-weight: 600;
}
.option-header{
    margin-bottom: 10px;
}
.operations img{
    width: 25px;
    height: 32px;
    margin-left: 465px;
    display: flex;
    position: absolute;
    top: 85px;
    cursor: pointer;
    display: none !important;
    z-index: 1000;
}
.operations:hover img{
    display: block !important;
}
.operations img.true {
    display: block !important;
}
.downloads-options {
    display: none !important;
    overflow: auto;
    max-height: 40%;
    padding: 15px;
    margin-left: 254px;
    z-index: 999;
    position: absolute;
    top: calc(100vh - 370px);
    min-width: 250px;
    background: #ddd;
    opacity: 0.8;
}
.downloads-options.true {
    display: block !important;
}
.downloads:hover .downloads-options{
    display: block !important;
}
.downloads:hover .downloads-header{
    font-weight: 600;
}
.downloads-header{
    margin: 5px 0px;
}
.downloads img{
    width: 25px;
    height: 32px;
    margin-left: 470px;
    display: flex;
    position: absolute;
    top: calc(100vh - 384px);
    cursor: pointer;
    display: none !important;
    z-index: 1000;
}
.downloads:hover img{
    display: block !important;
}
.downloads img.true {
    display: block !important;
}
.buildInput { padding: 10px 0px }
.buildInput .MuiInputBase-root {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: 600;
}
.buildInput .MuiInput-underline:before{ 
    border-bottom: unset;
}
.buildInput .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: unset;
}
.buildDateTime .react-datetime-picker{ 
    margin-bottom: 10px;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: 600;
}

.buildButton {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #ff6900;
    font-weight: 600;
}
.commentSectionAction {
    background: #ededed;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 10px;
    font-size: 11px;
}
.pasteAmount {
    padding: 0px 10px;font-size: 11px;margin-bottom: -10px;
}
/* .child{ display:none; }
.parent:hover .child{ display:block; } */