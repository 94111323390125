.MVPAnalyticsDashboard {
  // h3  {}
  padding: 20px;
  background: #ffffff;
  height: calc(100vh - 90px);
  .filter {
    display: flex;
    border-radius: 25px;
    padding: 10px;
    background: #fff;
    margin: 15px 15px;
    .each-filter-element {
      margin: auto 15px;
      select.form-control {
        border-radius: 15px !important;
      }
    }
  }
  .bp-l-b-e-v {
    display: flex;
    min-width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    margin-bottom: 20px;
    > div {
      margin: auto 15px;
    }
  }
  .sub-header {
    font: normal normal normal 20px/27px Graphik-Regular;
    color: #0e0e0e;
    margin: 0 15px;
  }
  .custom-card {
    display: flex;
    flex-wrap: wrap;
    .each-card {
      display: flex;
      background: #e6f3ff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;
      padding: 5px;
      margin: 15px;
      width: calc((100vw - 330px) / 5);
      .each-card-action {
        margin-bottom: auto;
        padding: 5px;
      }
      .each-card-content {
        flex-grow: 1;
        padding: 5px;
        cursor: pointer;
        .each-card-con-sub-h {
          font-weight: 600;
          word-break: break-all;
        }
        .each-card-con-sub-t {
          font-weight: 450;
          word-break: break-all;
        }
        .each-card-con-sub-f {
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          width: auto;
          margin-right: auto;
          padding: 5px;
        }
      }
      .each-card-more-action {
        margin-bottom: auto;
        padding: 5px;
        margin-left: auto;
        #dropdown-basic {
          background-color: #e6f3ff;
          border-color: #e6f3ff;
          &:after {
            content: unset;
            display: inline-block;
            vertical-align: middle;
          }
        }
        .moreIcon {
          z-index: 9;
          width: 20px;
          opacity: 0.54;
          &:after {
            content: url("/img/more.svg");
            display: block;
          }
        }
      }
    }
  }

  .e-c-card {
    flex-grow: 3;
    min-width: 250px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    .e-c-card-h {
      height: 30px;
      background: #f3f3f4;
    }
    .e-c-card-b {
      height: 130px;
      background: #f3f3f4;
      &:after {
        content: url("/img/printer-icon.png");
        display: flex;
        justify-content: center;
      }
    }
    .e-c-card-f {
      border-top: 1px solid #e0e0e0;
      // display: flex;
      // min-height: 53px;
      // justify-content: space-between;
      // padding: 10px;
      .e-c-card-f-h {
        display: flex;
        > div {
          &:nth-child(1) {
            font: normal normal normal 14px/19px Graphik-Regular;
            color: #ff6900;
            width: 50%;
            padding: 10px;
            border-right: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          &:nth-child(2) {
            font: normal normal bold 14px/19px Graphik-Medium;
            color: #1a1e26;
            width: 50%;
            padding: 10px;
            border-bottom: 1px solid #dddddd;
          }
        }
      }
      .e-c-card-f-e {
        display: flex;
        > div {
          width: 50%;
          padding: 10px;
          > div {
            > div {
              &:nth-child(1) {
                font: normal normal normal 14px/19px Graphik-Regular;
                color: #666666;
              }
              &:nth-child(2) {
                font: normal normal bold 14px/19px Graphik-Medium;
                color: #1a1e26;
              }
            }
          }
        }
      }
      .e-c-card-f-f {
        display: flex;
        font: normal normal normal 16px/22px Graphik-Regular;
        color: #000000;
        padding: 10px;
        cursor: pointer;
        > div {
          &:nth-child(1) {
            height: 30px;
            &:after {
              display: flex;
              justify-content: center;
              content: url("/img/printingReviewIcon.svg");
            }
          }
          &:nth-child(3) {
            margin: auto 10px;
            margin-left: auto;
            mask: url("/img/list_black_24dp.svg");
            background: #ff6900;
            mask-size: cover;
            height: 30px;
            width: 30px;
            cursor: pointer;
            // &:after  {
            //     display: flex;
            //     justify-content: center;
            //     content: url("/img/list_black_24dp.svg");
            // }
          }
        }
      }
    }
  }

  .MuiSvgIcon-root {
    font-size: 1rem !important;
  }
}

.card-container {
  min-width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  margin-right: 20px;
  margin-bottom: 20px;
  min-height: 40vh;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 0px 30px 0px 30px;
  flex-direction: column;
  .top-container {
    min-height: 40vh;
    align-items: center;
    display: flex;
    flex-direction: row;
    .chart-container {
      min-width: 30vw;
    }
    .legend-container {
      display: flex;
      justify-content: center;
      flex-grow: 2;
      flex-direction: column;
      .legend {
        padding: 5px 20px;
        margin: 10px 0px;
        width: -webkit-fill-available;
        align-items: center;
        display: flex;
        flex-direction: row;

        &:last-child {
          border-right: 0px solid #c4c4c4;
        }

        .display-status {
          height: 25px;
          width: 25px;
          border-radius: 50%;

          margin-right: 15px;
        }
        .running-status {
          background-color: #ff6900;
        }
        .alarm-status {
          background-color: #d60707;
        }
        .stopped-status {
          background-color: #374352;
        }
        .off-status {
          background-color: #bfbcbc;
        }

        .display-text {
          font: normal normal normal 20px/22px Graphik-Regular;
          color: #606060;
          margin-right: 10px;
          min-width: 85px;
          text-align: left;
          margin-bottom: 0px !important;
        }
        .display-percentage {
          font: normal normal normal 22px Graphik-Medium;
          color: #454545;
          min-width: 100px;
          text-align: right;
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .bottom-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c4c4c4;
    padding-top: 20px;
    color: #606060;
    padding-bottom: 20px;
    align-items: center;
   
    .stats {
      text-align: left;
      .label-header {
        font: normal normal normal 22px Graphik-bold;
        margin-right: 20px;
        color: #606060;
      }
      .label-value {
        font: normal normal normal 22px Graphik-Medium;
      }
    }
    .summary-message {
      font: normal normal normal 18px Graphik-Regular;
      color: #606060;
    }
  }
}
.error-msg {
  font: normal normal normal 22px Graphik-Regular;
  color: #606060;
}
.recharts-default-tooltip {
  border-radius: 5px;
  border: 0px none !important;
}
.recharts-tooltip-label {
  font: normal normal normal 14px Graphik-Regular !important;
  color: #606060 !important;
}

.printer-details{
  display: flex;
  min-width: 20%;
  justify-content: space-around;
  text-align: left;
  margin-right: 10%;
  .e-c-card-f-e>div{
    margin-bottom: 30px;
  }
}
 .summary-header {
    text-align: left;
    font: normal normal 600 28px/39px Graphik-Regular;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
    margin-top: 30px;
 }
 .label-printer{
    text-align: left;
    font: normal normal normal 18px/20px Graphik-Regular;
    letter-spacing: 0px;
    color: #606060;
    margin-bottom: 10px;
 }
 .value-printer {
    font: normal normal 800 20px/20px Graphik-Regular;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
 }
 .value-printer-print-name{
  width: 200px;
  overflow-wrap: anywhere;
  margin-right: 100px;
  line-height: 1.5;
 }
.status-button{
  padding: 10px 20px !important;
}
.MuiInputBase-root{
  height:49px !important
}