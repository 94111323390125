.bundlesListContainer {
  height: 200px;
  overflow: scroll;
  text-align: left;
}

table.bundles,
th.bundles,
td.bundles {
  border: 1px solid black;
  padding-left: 10px;
  padding-right: 10px;
}

.bundle-list {    
  display: flex;
  margin: 5px 0px;
}
.ellipsis-text {
  max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.bundles-queue-ellipsis-text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.bundles-queue-page {
  width: calc(100% - 30px);
  margin: 15px;
}
.bundles-queue-table {
  width: 100%;
}