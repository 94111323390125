.MVPBuilds {
  width: 50vw;
  height: 100%;
  margin-left: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e1e1e1;
  overflow: auto;
  .b-header {
    font: normal normal bold 20px/27px Graphik-Medium;
    color: #0e0e0e;
    margin: 20px;
  }
  .your-build {
    .each-b-section {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #ff69003d;
      border: 1px solid #b8b8b8;
      border-radius: 8px;
      margin: 0px 20px 20px 20px;
      .t-s {
        display: flex;
        margin: 15px;
        .t-s-b {
          font: normal normal bold 22px/30px Graphik-Medium;
          letter-spacing: 0px;
          color: #1a1e26;
          margin: auto 0;
          margin-right: 10px;
        }
        .t-s-n {
          font: normal normal normal 18px/24px Graphik-Regular;
          letter-spacing: 0px;
          color: #666666;
          margin: auto 0;
        }
        .t-s-bu {
          margin: auto 0;
          margin-left: auto;
        }
      }
      .b-s {
        display: flex;
        margin-bottom: 15px;
        .b-s-l {
          font: normal normal normal 22px/30px Graphik-Regular;
          letter-spacing: 0px;
          color: #1a1e26;
          margin: auto 15px;
        }
        .b-s-p {
          width: calc(100% - 270px);
          .b-s-p-t {
            display: flex;
            padding: 10px;
            .b-s-p-t-p {
              font: normal normal bold 22px/30px Graphik-Medium;
              letter-spacing: 0px;
              color: #707070;
              margin-right: 20px;
            }
            .b-s-p-t-t {
              font: normal normal normal 22px/30px Graphik-Regular;
              letter-spacing: 0px;
              color: #707070;
            }
            .b-s-p-t-s {
              margin-left: auto;
            }
          }

          .b-s-p-b {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 20px;
            padding: 10px;
            margin: 5px 10px;
            .progress-bar {
              background-color: #ff6900 !important;
              &.bg-success{
                background-color: #ff6900!important; 
              }
              
            }
          }
        }
      }
    }
  }
  table {
    margin: 20px;
    width: calc(100% - 40px);
  }
  .buildTable {
    // overflow: auto;
    // max-height: 300px;
  }
}
