.leftNavSection {
  display: none;
  background: #1F2A37;
  width: 120px;
  //height: calc(100vh - 90px);
  min-height: 100%;
  background: transparent
    linear-gradient(108deg, #1F2A37 0%, #1F2A37 12%, #1c272e 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 3px 0px 12px #00000057;
}
.each-section {
  display: flex;
  overflow: hidden;
  padding: 20px 0px;
  margin: 0px;
  cursor: pointer;
  .content {
    min-width: 150px;
    display: inline-block;
    margin-top: 10px;
  }
  .each-section-label {
    font: normal normal normal 18px/27px Graphik-Regular;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    padding-left: 40px;
  }
  .each-section-icon {
    padding-left: 10px;
  }
  &:nth-child(1) {
    .each-section-icon {
      background: url("/prepare-icon.svg") no-repeat;
      height: 38px;
      width: 31px;
    }
  }
  &:nth-child(2) {
    .each-section-icon {
      background: url("/print-icon.svg") no-repeat;
      height: 31px;
      width: 31px;
    }
  }
  
  &:nth-child(3) {
    .each-section-icon {
      background: url("/new_analytics.svg")no-repeat;
      height: 25px;
      width: 34.5px;
      background-size: 85%;
      span {
        padding-left: 35px!important;
      }
    }
  }
  &:nth-child(4) {
    padding-left: 20px;
    .each-section-icon {
      background: url("/new_configure.svg") no-repeat;
      height: 33px;
      width: 33px;
      background-size: 85%;
      span {
        padding-left: 35px!important;
      }
    }
    
  }
  &:nth-child(5) {
    padding-left: 20px;
    .each-section-icon {
      background: url("/documents-icon.svg") no-repeat;
      height: 27px;
      width: 21px;
      span {
        padding-left: 25px!important;
      }
    }
  }
  &:nth-child(6) {
    .each-section-icon {
      background: url("/resource.svg") no-repeat;
      height: 35px;
      width: 35px;
      span {
        padding-left: 25px!important;
      }
    }
  }
  &:nth-child(7) {
    .each-section-icon {
      background: url("/admin.svg") no-repeat;
      height: 35px;
      width: 35px;
      span {
        padding-left: 25px!important;
      }
    }
  }
  &.vertical-active {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 130px;
      background-color: #ff6900;
      top: 0;
      border-top: 6px solid #ff6900;
    }
    .each-section-label {
      font: normal normal normal 18px/27px Graphik-SemiBold;
      color: #fff;
    }
  }
}
