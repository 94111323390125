.topNavSection {
	background: #1F2A37;
	height: 90px;
	display: flex;
	padding: 0 45px;

	.flex-space {
		flex: 1 1 auto;
	}

	.logo {
		margin: auto 30px;
		cursor: pointer;

		img {
			width: 200px;
		}
	}

	.flex-small-space {
		flex: 1 1 auto;
	}

	.separator {
		margin: auto;
		height: 60px;
		margin-right: 4px;
		margin-bottom: 8px;

		&:after {
			content: url("/img/separator.svg");
		}
	}

	// .on-signout {
	//   margin: auto;
	//   height: 60px;
	//   margin-right: 4px;
	//   margin-bottom: 40px;
	//   cursor: pointer;
	// }

	.logo-t {
		font: normal normal bold 32px/42px Graphik-Semibold;
		letter-spacing: 0px;
		color: #ff6900;
		margin: auto;
		margin-left: 8px;
	}

	.on-process {
		height: 60px;
		margin: auto 0;
		margin-right: 4px;

		&:after {
			content: url("/img/buildList.svg");
		}
	}
}

.recentBuildList {
	position: absolute;
	height: calc(100vh - 90px);
	top: 90px;
	width: 100vw;
	background: rgba(30, 42, 50, 0.12);
	z-index: 9;
}

// .sign-out-btn {
//   align-items: center;
//   display: flex;
//   margin-right: 20px;
// }

// .user-name,
// .welcome-message {
//   color: white;
// }

// .sign-out-text {
//   color: white;
//   align-items: center;
//   margin-right: 0px;
//   margin-left: 30px;
// }

// .welcome-message {
//   display: flex;
//   align-items: center;
//   margin-right: 40px;
//   text-align: center;
// }

// .sign-out-text {
//   font-size: 20px;
// }

// .user-name {
//   font-size: 20px;
// }

// .message {
//   margin-bottom: 0px;
// }

/*******Hamburger Menu******/
#menu-toggle {
	display: block;
	margin-top: 35px;
	z-index: 99;
	-webkit-user-select: none;
	user-select: none;

	input {
		display: block;
		width: 40px;
		height: 32px;
		position: absolute;
		top: 28px;
		right: 38px;
		cursor: pointer;
		opacity: 0;
		z-index: 2;
		-webkit-touch-callout: none;

		&:checked {
			~ {
				span {
					opacity: 1;
					transform: rotate(45deg) translate(-2px, -1px);
					background: #fff;

					&:nth-last-child(3) {
						opacity: 0;
						transform: rotate(0deg) scale(0.2, 0.2);
					}

					&:nth-last-child(2) {
						opacity: 1;
						transform: rotate(-45deg) translate(0, -1px);
					}
				}

				ul {
					transform: none;
					opacity: 1;
				}
			}
		}
	}

	.line {
		display: block;
		width: 30px;
		height: 3px;
		margin-bottom: 5px;
		position: relative;
		background: #cdcdcd;
		border-radius: 3px;
		z-index: 1;
		transform-origin: 3px -2px;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;

		&:first-child {
			transform-origin: 0% 0%;
		}

		&:nth-last-child(2) {
			transform-origin: 0% 100%;
		}
	}
}

#menu {
	position: absolute;
	width: 640px;
	margin: 28px 0 0 0;
	padding: 0 60px;
	right: -50px;
	background: #1F2A37;
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(100%, 0);
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
	z-index: 99;
	height: calc(100vh - 90px);
	font-family: 'Graphik-Regular';

	li {
		padding: 20px 0;
		font-size: 18px;
		color: #fff;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		border-bottom: 1px solid #7B868E;
		cursor: pointer;

		img {
			padding-right: 23px;
		}

		&:hover {
			color: #FF6900;
		}

		.user-name {
			font-size: 24px;
			font-family: 'Graphik-Medium';
			// text-transform: capitalize;
		}
	}
}