section.page {
    &.review-page {
        background: #F4F4F5;
        // header{
        //     &:first-child{
        //         display: none;
        //     }
        // }

        header{
            background: #fff;
            &.top-header-bg {
                background: #FFF;
                box-shadow: 0px 0px 6px #00000029;          
            }
        }
        .options .option.search input.search-box {
            border-radius: 25px!important;
            border: 2px solid #fff;
            border-radius: 25px;
            height: 53px;
            color: #1A1E26;
            padding: 0 32px 0 56px;
            background: #F4F4F4  url(/search-icon.svg) no-repeat 14px 16px!important;
            font: normal normal 600 16px/21px Graphik-Regular;
            &:focus {
                border: 2px solid #FF6900;
            }
        }
        .scrollable-content {
            dl {
                padding: 0;
                margin: 0!important;
                dd {
                    margin-left: 0 !important;
                }
            }
        }
        .review-page > section dl 
        {
            padding: 0px !important;
            dd {
                margin-left: 0 !important;
            }
        }
        
        .display-card {
            // height: 140px;
            height: 110px;
            width: 95.5%!important;
            border: 2px solid transparent;
            .display-card-row {
                min-height: auto!important;
                .display-card-header label
                {
                    color: #ff6900;
                    width: 300px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-bottom: 2px solid #F4F4F5;
                    padding-bottom: 10px;
                }
                .details  {
                    display: flex;
                    font-family: 'OpenSans-Regular';
                    font-size: 12px;
                    label {
                        min-width: 130px;
                        margin-bottom: 12px!important;
                        color: #666666;
                    }
                    span {
                        font-weight: bold;
                        line-height: 1.2;
                    }
                }
            }
            .thumbnail-preview {
                display: inline-flex;
                height: 80px;
                align-items: center;
                float: left;
            }
            .part-image {
                height: 80px;
                // width: 110px;
                float: left;
                margin: 10px !important;
            }
            .default-thumb-image {
                height: 45px;
                float: left;
                width: 100px;                
            }
            .build-image {
                width: 115px;
                height: 95px;
                float: left;
            }
           
        }
        .display-parts {
            margin: 20px 15px 0 0;
            height: calc(100% - 250px)!important;
            &.edit {
                height: calc(100% - 150px)!important;
            }
        }
        .tap-text {
            text-align: center;
            color: #BFBFBF;
            font-size: 18px;
            padding: 22px 0px;
            margin-right: 50px;
            font-weight: 600;
        }
        .sub-heading {
            color: #1A1E26;
            font-size: 16px;
            font-weight: 600;
        }
        .select-part {
            right: 5px;
            top: 0px;
        }
        .right-view {
            display: grid!important;
            width: 63%;
            background: #fff;
            .tab-bar{
                height: 90px;
                padding: 25px;
                position: relative;
                z-index: 1;
                box-shadow: 0px 15px 10px -15px #00000029;
                background: #fff;
                display: flex;
                font-size: 14px;
                .measure-btn {
                    display: flow-root;
                    text-align: center;
                    line-height: 28px;
                }
            }
        }
        .back-container {
            height: 60px!important;
        }
        .review-options {
            display: inline-flex;
            width: 94%;
        }
    }
} 
.review-page > section dl {
    padding: 0!important;
    dd {
        margin-left: 0!important;
    }
    .display-card {
        .preview-img {
            display: block!important;
            width: 100%;
        }
        .part-image {
            padding: 0!important;
        }
    }
    
}
