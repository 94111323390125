.MVPPrintDashboard {
  // h3  {}
  padding: 20px;
  background: #ffffff;
  height: calc(100vh - 90px);

  .filter {
    display: flex;
    border-radius: 25px;
    padding: 10px;
    background: #fff;
    margin: 15px 15px;

    .each-filter-element {
      margin: auto 15px;

      select.form-control {
        border-radius: 15px !important;
      }
    }
  }

  .sub-header {
    font: normal normal normal 20px/27px Graphik-Regular;
    color: #0e0e0e;
    margin: 0 15px;
  }

  .custom-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .each-card {
      display: flex;
      background: #e6f3ff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;
      padding: 5px;
      margin: 15px;
      width: calc((100vw - 330px) / 5);

      .each-card-action {
        margin-bottom: auto;
        padding: 5px;
      }

      .each-card-content {
        flex-grow: 1;
        padding: 5px;
        cursor: pointer;

        .each-card-con-sub-h {
          font-weight: 600;
          word-break: break-all;
        }

        .each-card-con-sub-t {
          font-weight: 450;
          word-break: break-all;
        }

        .each-card-con-sub-f {
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          width: auto;
          margin-right: auto;
          padding: 5px;
        }
      }

      .each-card-more-action {
        margin-bottom: auto;
        padding: 5px;
        margin-left: auto;

        #dropdown-basic {
          background-color: #e6f3ff;
          border-color: #e6f3ff;

          &:after {
            content: unset;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .moreIcon {
          z-index: 9;
          width: 20px;
          opacity: 0.54;

          &:after {
            content: url("/img/more.svg");
            display: block;
          }
        }
      }
    }
  }

  .e-c-card {
    //  flex-grow: 3;
    min-width: 300px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;

    .e-c-card-h {
      height: 30px;
      background: #f3f3f4;
    }

    .e-c-card-b {
      height: 200px;
      background: #f3f3f4;

      &:after {
        content: url("/img/printer-icon.png");
        display: flex;
        justify-content: center;
        min-height: 200px;
        align-items: center;
      }
    }

    .e-c-card-f {
      border-top: 1px solid #e0e0e0;
      // display: flex;
      // min-height: 53px;
      // justify-content: space-between;
      // padding: 10px;
      // height: 300px;
      justify-content: space-around;
      display: flex;
      flex-direction: column;

      .e-c-card-f-h {
        display: flex;

        >div {
          &:nth-child(1) {
            font: normal normal normal 16px/19px Graphik-Regular;
            color: #ff6900;
            width: 100%;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }

          &:nth-child(2) {
            font: normal normal normal 14px/19px Graphik-Regular;
            color: #666666;
            padding: 10px;
            border-bottom: 1px solid #dddddd;
            width: 170px;
            word-break: break-word;
          }
        }
      }

      .e-c-card-f-e {
        display: flex;

        >div {
          width: 50%;
          padding: 10px;

          >div {
            >div {
              &:nth-child(1) {
                font: normal normal normal 13px/19px Graphik-Regular;
                color: #666666;
              }

              &:nth-child(2) {
                font: normal normal bold 13px/19px Graphik-Medium;
                color: #1a1e26;
                width: 153px;
                word-break: break-word;
              }
            }
          }
        }
      }

      .e-c-card-f-f {
        display: flex;
        font: normal normal normal 16px/22px Graphik-Regular;
        color: #000000;
        padding: 10px;
        cursor: pointer;
        justify-content: space-between;
        width: 100%;
        position:absolute;
        bottom: 0;

        >div {
          &:nth-child(1) {
            height: 30px;

            &:after {
              display: flex;
              justify-content: center;
              content: url("/img/printingReviewIcon.svg");
            }
          }

          &:nth-child(3) {
            // margin: auto 10px;
            // margin-left: auto;
            // mask: url("/img/list_black_24dp.svg");
            // background: #ff6900;
            // mask-size: cover;
            // height: 30px;
            // width: 30px;
            // cursor: pointer;
            // &:after  {
            //     display: flex;
            //     justify-content: center;
            //     content: url("/img/list_black_24dp.svg");
            // }
          }
        }
      }
    }
  }
  .print-actions {
    width: 30px;
    cursor: pointer;
  }
  .print-diagnostics{
    width: 36px;
    cursor: pointer;
  }
  
}

.height-auto {
  height: auto !important;
}

.printer-tool-configuration-list {
  li {
    background-color: #F4F4F5;
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 10px;
    flex-direction: column;
    list-style: none;
  }

  .list-values {
    display: flex;
    justify-content: space-between;

    label {
      font: normal normal bold 15px/19px Graphik-Medium !important;
    }

    label:nth-child(1) {
      width: 20%
    }

    label:nth-child(2) {
      width: 70%
    }

    label:nth-child(3) {
      width: 35%;

      //  margin-right: 30px;
      span {
        margin-left: 10px;
      }
    }


  }

  .heading {
    label {
      font: normal normal normal 13px/19px Graphik-Medium !important;
      color: #707070;
    }
  }
}

.printer-name {
  display: flex;
  flex-direction: column;

  label:nth-child(1) {
    font: normal normal bold 15px/19px Graphik-Medium !important;
    color: #1a1e26;
  }

  label:nth-child(2) {
    font: normal normal bold 20px/19px Graphik-Medium !important;
    color: #ff6900;
  }
}

.configuration-heading {
  font: normal normal bold 18px/19px Graphik-Medium;
  text-align: center;

}

.tool-tip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    padding: 5px;
    display: flex;
    flex-direction: column;
    font: normal normal bold 13px/19px Graphik-Regular;
   
    label:nth-child(2){
      color: #ff6900;
      text-align: center;
    }

  }
  div:nth-child(1){
   padding-right: 20px;
  }
}
.e-c-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.fetch-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.e-c-card-f {
  position: relative;
}
.printer-label{
  font: normal normal normal 13px/19px Graphik-Regular;
  color: #666666;
}
.diagnostics-popup{
  font: normal normal normal 16px/19px Graphik-Regular;
  display: flex;
    justify-content: space-around;
 
}
.diagnostics-label{
  font: normal normal normal 18px/19px Graphik-Regular !important;
  color: #666666;
}
.diagnostics-value{
  font: normal normal normal 18px/19px Graphik-Regular !important;
  color: #333;
}
.diagnostics-text-block{
  display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
}
.sub-head{
  font: normal normal normal 16px/19px Graphik-Medium !important;
}