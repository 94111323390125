.sideBarContainer {
  top: 0;
  bottom: 0;
  left: 0;

  min-width: 10%;
  width: 250px;

  height: 100%;
  background-color: rgba(220, 220, 220, 0.5);

  display: flex;
  flex-direction: column;
  resize: horizontal;
  overflow: auto;
}

.sideBarParamsBlock {
  background-color: rgba(200, 200, 200, 0.6);
}

.sideBarVersionBlock {
  text-align: center;
}
