
.printers {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 60vh;
    .e-c-card {
        flex-grow: 3;
        min-width: 250px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 6px;
        margin-right: 20px;
        margin-bottom: 20px;
        .e-c-card-h {
            height: 30px;
        }
        .e-c-card-b {
            height: 130px;
             &:after  {
                display: flex;
                justify-content: center;
                content: url("/img/printer-icon.png");
              }
        }
        .e-c-card-f {
            border-top: 1px solid #E0E0E0;
            display: flex;
            min-height: 53px;
            justify-content: space-between;
            padding: 10px;
        }
    }
}
