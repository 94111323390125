.buildReview {
  display: flex;
  height: calc(100vh - 90px);

  .bp-l {
    min-width: 330px;
    max-width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 3px 6px #00000029;
    border-right: 1px solid #e1e1e1;
    height: 100vh;

    .bp-l-h {
      height: 60px;
      display: flex;

      .backIcon {
        margin: auto 10px;
        background: url(/img/back-arrow.svg) no-repeat;
        height: 22px;
        width: 36px;
        cursor: pointer;
      }

      .label {
        margin: auto 10px;
        font: normal normal bold 22px/30px Graphik-Medium;
        letter-spacing: 0px;
        color: #1a1e26;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .border-botm {
      border: 1px solid #707070;
      opacity: 0.57;
    }

    .bp-l-b {
      height: calc(100% - 131px);
      font-family: "Graphik-Regular";

      .bp-l-b-e {
        display: flex;
        margin: 15px 0px 10px 0px;
        font: normal normal normal 16px/22px "Graphik-Regular";
        color: #666666;

        .bp-l-b-e-p {
          position: relative;

          .cancel-a-b-i {
            position: absolute;
            top: -10px;
            right: 2px;
            mask: url("/img/cancel_black_24dp.svg");
            background: #ff6900;
            mask-size: cover;
            height: 25px;
            width: 25px;
          }
        }

        .bp-l-b-e-pr {
          >div {
            &:nth-child(1) {
              font: normal normal bold 14px/19px Graphik-Medium;
              letter-spacing: 0px;
              color: #393939;
              margin-bottom: 5px;
            }

            &:nth-child(2) {
              font: normal normal bold 14px/19px Graphik-Medium;
              letter-spacing: 0px;
              color: #ff6900;
              cursor: pointer;
            }
          }
        }
      }

      .bp-l-b-e-v {
        display: flex;
        margin: 10px 0px;
        font: normal normal bold 14px/19px Graphik-Medium;
        letter-spacing: 0px;
        color: #393939;
      }

      .bp-l-b-e-r {
        .custom-control {
          min-height: 2rem !important;

          .custom-control-label {
            font-size: 14px !important;
            padding-top: 5px !important;
            padding-left: 5px !important;
          }
        }
      }
    }

    .bp-l-f {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-top: 1px solid #eeeeee;
      opacity: 1;
      display: flex;
      justify-content: center;
      padding: 20px;
      padding-bottom: 0px;
      align-items: center;
      height: 100%;

      button.btn,
      .btn-build-review-primary {
        margin: auto 15px;
        //  min-width: 100px;
        margin-bottom: 10px;
        max-width: 190px;
        min-width: 190px;
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }

  .bp-r {
    background: #fff 0% 0% no-repeat padding-box;
    font: normal normal bold 17px/27px Graphik-Regular;
    width: 100%;
    position: relative;
    // overflow: auto;

    // padding: 15px 0px;
    .bp-r-h {
      letter-spacing: 0px;
      color: #0e0e0e;
      height: 62px;
      display: flex;

      .o-s-a {
        display: flex;
        margin-right: 200px;
        padding: 5px 10px;
        margin-left: auto;
        margin-top: -7px;
        // border: 1px solid #ff6900;
        border-radius: 2px;
        //  width: 170px;
        justify-content: space-between;
        margin-bottom: auto;
        cursor: pointer;

        .active {
          color: #ff6900;
        }
      }

      .o-s-a-d {
        width: 1px;
        background: #aaa;
      }
    }

    .bp-r-h1 {
      font-size: 22px;
      font-family: "Graphik-Regular";
      height: 62px;
      display: flex;
      margin-top: -15px;
      margin-bottom: 15px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.54);

      >div {
        margin: auto 0;
      }
    }
  }

  .buildReviewScreen {
    display: flex;
    flex-grow: 1;
    // height: 100%; //calc(100vh - 285px);
    //height: calc(100vh - 230px);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    // overflow: hidden;
    background: #F4F4F5;
    z-index: 2;

    .actualView {
      width: calc(50% - 15px);
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;
      margin-right: 15px;

      .viewHeader {
        padding: 15px;
        display: flex;
        justify-content: space-between;
      }

      .viewBody {
        height: 100%;
        padding: 15px;
        padding-top: 0px;

        >div {
          height: 92%;
        }
      }

      &.w-0 {
        width: 0px;
      }

      &.w-100 {
        width: 100%;
      }
    }

    .sliceView {
      height: 100%;
      width: calc(50% - 15px);
      // background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;

      .viewHeader {
        padding: 15px;
        display: flex;
        justify-content: space-between;
      }

      .viewBody {
        height: 100vh;
        // padding: 15px;
        padding-top: 0px;

        >div {
          height: 100%;
        }
      }

      &.w-0 {
        width: 0px;
      }

      &.w-100 {
        width: 100%;
      }
    }
  }

  .show-parts-adding {
    display: flex;

    .buildReviewScreen {
      width: 50%;
    }
  }
}

.expand-build {
  margin: auto 10px;
  // mask: url("/img/open_in_full_black_24dp.svg");
  background: black;
  mask-size: cover;
  height: 24px;
  width: 24px;
  margin-left: auto;
  cursor: pointer;
}

.collapse-build {
  margin: auto 10px;
  mask: url("/img/close_fullscreen_black_24dp.svg");
  background: black;
  mask-size: cover;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.d-none {
  display: none;
}

.slice-btn-container {
  position: absolute;
  top: -68px;
  right: 0px;

  button {
    padding: 2px 20px;
  }

  .btn-build-review-secondary:first-child {
    margin-right: 10px;
  }
}

h4.boldlabel {
  margin: 15px;
  font: normal normal bold 22px/30px Graphik-Medium;
  letter-spacing: 0px;
  color: #1a1e26;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
}

.icon-expand-collapse {
  svg {
    fill: #ff6900;
  }
}

.nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.nav-tabs .nav-link {
  border: 0px solid transparent;
  min-width: 50px;
  margin: 5px 25px;
  padding: 5px !important;
}

.nav-item {
  color: #666666;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #ff6900;
}

.config-label {
  font: normal normal normal 14px/22px Graphik-Regular;
  color: #666666;
  margin-bottom: 0px;
  margin-left: 5px;
}

.config-value {
  font: normal normal bold 14px/19px Graphik-Medium;
  letter-spacing: 0px;
  color: #393939;
  margin-left: 5px;
}

.config-detail {
  display: flex;
  flex-direction: column;

  .multi-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

.config-details {
  max-height: 32vh; // 52vh
  overflow: auto;
  padding-left: 5px;
}

.bp-l-f {
  display: flex;
  flex-direction: column;
}

.bundle-checkbox {
  display: flex;
  flex-direction: row;
  align-self: center;

  label {
    margin-top: 8px;
    margin-left: 3px;
  }
}

.MuiCheckbox-colorPrimary.Mui-checked,
.MuiCheckbox-root {
  color: #ff6900 !important;
}

.btn-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  button {
    max-width: fit-content !important;
    min-width: auto !important;
    padding: 0px 12px !important;
    font-size: 15px !important;
    margin: 10px 5px !important;

    &:nth-child(2) {
      min-width: 165px !important;
    }
  }
}

.build-tools {
  list-style: none;
  padding: 0px 10px;

  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f7;
    padding: 10px;
    margin: 5px 0px;
    color: #1a1e26;
    font: normal normal 600 14px/18px Graphik-Medium;
    border-radius: 6px;

    cursor: pointer;

    span {
      text-align: left;
    }
  }

  li:first-child {
    font: normal normal normal 14px/18px Graphik-Regular;
    color: #666666;
    background-color: #fff;
    padding-top: 0px;
  }
}

.tool-configuration-list {
  list-style: none;
  padding: 0px 10px;
  width: 100%;

  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 5px 0px;
    color: #1a1e26;

    //font: normal normal 600 14px/18px Graphik-Medium;
    // border-radius: 6px;
    span {
      text-align: left;
      flex: 1;
      font: normal normal 600 16px/22px Graphik-Regular;
      color: #1a1e26;
    }

    span:last-child {
      font: normal normal 600 18px/24px Graphik-Regular;
      text-align: left;
    }
  }
}

.modal-body {
  h3 {
    color: #ff6900;
    font: normal normal bold 20px/33px Graphik-Medium;
    margin-left: 20px;
  }
}

.modal-footer {
  button {
    padding: 8px 88px !important;
  }
}

.font-14 {
  font-size: 14px;
}

.print-label {
  font: normal normal normal 14px/22px Graphik-Regular !important;
  color: #666666 !important;
  margin-bottom: 0px !important;
}

.print-value {
  font: normal normal bold 14px/19px Graphik-Medium !important;
  letter-spacing: 0px !important;
  color: #393939 !important;
  margin: 0px !important;
}

.cutSim-tooltip {
  padding: 10px;
  box-shadow: -4px 3px 18px -1px rgba(179, 179, 179, 1);
  border: none;
  border-radius: 10px;
  font: normal normal 600 16px/22px "Graphik-Regular";

  .popover-header {
    background: none;
    border: none;
  }

  ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;

    li {
      flex-direction: row;
      display: flex;
      padding: 5px 0px;
      align-items: center;

      .circle-shape {
        margin-right: 20px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
      }

      &:first-child {
        .circle-shape {
          border: 1px solid #b5b1b1;
        }
      }
    }
  }
}

.cutSim-Legend-btn {
  padding: 0px 20px 0px 40px !important;
  height: 34px;
  border: 1px solid #9b9b9b59 !important;
  border-radius: 6px !important;
  background: #ffff url("/img/legend-icon.svg") no-repeat 10px center !important;
  font: normal normal normal 14px/22px "Graphik-Regular" !important;
  color: #393939 !important;
}

.paste-consumption-btn {

  padding: 0px 10px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f7;
  padding: 10px;
  margin: 5px 0px;
  color: #1a1e26;
  font: normal normal 600 14px/18px Graphik-Medium;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 30px;

  cursor: pointer;

  span {
    text-align: left;
  }



}

.recharts-legend-item-text {
  font: normal normal 400 16px/18px Graphik-Regular;
}

.recharts-tooltip-wrapper {
  .customTooltip {
    border: 1px solid #f5f5f5 !important;

  }
}

.tool-life-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #dfdddd;
  margin-bottom: 20px;


  div {
    flex-direction: column;
    display: flex;
    margin-left: 20px;

    label.title {
      font: normal normal bold 16px/30px Graphik-Medium;
      color: #666;
      margin-bottom: 0px;
    }

    label.value {
      font: normal normal bold 24px/30px Graphik-Medium;
    }
  }
}
.warning-container{
  div:not(:last-child){
border-bottom: 1px solid #aeaeae;
  }
}