.card {
    width: 18rem;
    height: auto;
    display: inline-block;
    margin: 10px;
    border-width: 2px;
    overflow: hidden;
}
.card-img-container{
    height: 13.5rem;
    text-align:center;
    overflow: hidden;
    margin: 0;
}
.card-img-top{
    max-height:100%;
    width: auto;
    /*height: 18rem;*/
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.header-section {
    display: flex;
}
.header-section .each-section {
    margin: auto 10px;
}
.card-footer {
    display: flex;
}
.card-footer button.btn {
    color: #fff;
    background-color: #ff9900;
    border-color: #fff;
}
.card-footer button.btn:hover {
    opacity: 0.8;
}
.card-footer button.btn:active {
    opacity: 1;
    background-color: #e88b01;
}
.card-body {
    padding: 10px 1.25rem;
}
.card-title {
    margin-bottom: 0;
}
.card-text {
    margin-top: 5px
}
.add-file {
    position: absolute;
    bottom: 100px;
    background: #fff;
    padding: 10px;
    width: calc(100% - 10px);
    left: 5px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.catalog-dialog .each-content{
    margin-bottom: 10px;
}
.MuiMenu-paper {
    box-shadow: 0px 1px 1px 1px rgb(255 255 255 / 2%), 0px 1px 1px 1px rgb(255 255 255 / 20%), 0px 1px 5px 1px rgb(0 0 0 / 2%) !important;
}